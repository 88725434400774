/**
 * note: Gill Sans Nova is not free for commercial use. John Lewis presumably has a license for it, and I'm under the
 * impression that their license covers our use of it (this is on their domain after all). So, I downloaded this file
 * from https://internationalpayments.johnlewisfinance.com/Account/content/fonts/gillsansforjl-regular.woff, which is
 * the old accounts-ui implementation that HiFX provided to John Lewis.
 */
import regularFontUrl from "../fonts/gill-sans-for-jl-regular.woff";
// bold font URL purposefully not included because John Lewis never seems to use bold lettering.

import NewWindowIcon from "../images/new-window-icon.svg";
import WhiteChevronRightIcon from "../images/white-chevron-right-icon.svg";

const colors = {
  backdropColor: "#fff",
  backgroundColor: "#fff",
  headingColor: "#5f5f5f",
  textColor: "#5f5f5f",
  linkColor: "#427730",
  link: {
    mainColor: "#003cbe"
  },
  buttonBackgroundColor: "#4aa851",
  buttonActiveBackgroundColor: "#4aa851", // FIX ME: Update this with finalized hover color
  buttonDisabledBackgroundColor: "#a6d8aa", // FIX ME: not official lol. andre made this colour up
  buttonGradientColor1: "#4aa851",
  buttonGradientColor2: "#3e8e44",
  buttonTextColor: "#fff",
  inverseBackgroundColor: "#fff",
  inverseTextColor: "#747678",
  inputTextColor: "#999",
  normalInputBorderColor: "#999",
  invalidInputBorderColor: "#cc0000",
  invalidInputBackgroundColor: "#fff",
  focusedInputBorderColor: "#4aa851",
  focusedInputBackgroundColor: "#edf7ec",
  spinnerColor1: "#e0f0db",
  spinnerColor2: "#427730",
  horizontalRuleColor: "#dedbd2"
};

const typography = {
  defaultFontSize: "1.6rem",
  headingFontSize: "2.4rem",
  secondaryFontSize: "1.6rem",
  headingFontFamily: [
    "Gill Sans",
    "Arial",
    "Helvetica",
    "sans-serif"
  ].join(","),
  fontFamily: [
    "Arial",
    "Helvetica",
    "sans-serif"
  ].join(","),
  fontFaceRules: `
    @font-face {
      font-family: 'Gill Sans';
      font-weight: normal;
      font-style: normal;
      font-display: swap;
      src: local('Gill Sans'), local('GillSans'), url('${regularFontUrl}') format('woff');
    }
  `,
  headingFontWeight: "normal",
  buttonFontWeight: "normal",
  buttonTextTransform: "none",
  labelFontWeight: "bold"
};

const display = {
  headerAlignment: "center"
};

const forms = {
  extraFormStyles: `
    form input {
      transition: border-color .2s, background-color .2s;
    }
    form input:hover {
      border-color: ${colors.focusedInputBorderColor};
      background-color: ${colors.focusedInputBackgroundColor};
    }
  `
};

const footer = {
  footerLinksConstrictorMaxWidth: "991px",
  extraFooterLinksContainerStyles: `
    li {
      padding: 24px;
      line-height: ${typography.secondaryFontSize};
      border-color: ${colors.horizontalRuleColor};
      min-width: 230px;
      width: 100%;
      text-align: left;
      border-bottom: 1px solid ${colors.horizontalRuleColor};
      :first-child {
        border-top: 1px solid ${colors.horizontalRuleColor};
      }
      @media (min-width: 480px) {
        width: auto;
        border-bottom: none;
        :first-child {
          border-top: none;
        }

        :nth-child(4) {
          text-align: right;
          a {
            text-align: left;
          }
        }
      }

      a {
        display: inline-block;
        :hover {
          color: #427730;
        }
      }

      a[target="_blank"]::before {
        display: inline-block;
        position: relative;
        top: 2px;
        margin-right: 10px;
        width: 18px;
        height: 15px;
        content: "";
        background-image: url("${NewWindowIcon}");
        background-repeat: no-repeat;
      }

      a[target="_blank"]::after {
        display: block;
        content: "(opens in a new window)";
        font-size: 1.2rem;
        color: #999;
        margin-left: 28px;
      }
    }
  `,
  extraFooterLegalTextContainerStyles: `
    margin: 32px 0;
    font-size: 1.2rem;
  `
};

const buttons = {
  extraButtonStyles: `
    &[data-loading="false"]::after {
      display: inline-block;
      position: relative;
      top: 4px;
      margin-left: 12px;
      width: 7px;
      height: 15px;
      content: "";
      background-image: url("${WhiteChevronRightIcon}");
      background-repeat: no-repeat;
    }
    :hover:enabled, :focus {
      background: linear-gradient(to bottom, ${colors.buttonGradientColor1} 0%, ${colors.buttonGradientColor2} 100%);
    }
    padding-top: 9px;
    padding-bottom: 9px;
  `
};

const links = {
  inverseLinkTextDecoration: "none"
};

export default {
  ...colors,
  ...typography,
  ...display,
  ...forms,
  ...footer,
  ...buttons,
  ...links
};
