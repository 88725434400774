import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { Get } from "react-axios";
import maskEmail from "@xe/util-mask-email";

import { getContentString } from "../branding";
import TimeoutForm from "../form-components/timeout-form";
import CommonHeader from "../common-components/common-header";
import CommonFooter from "../common-components/common-footer";
import { MainContentContainer, SectionFooter } from "../common-components/containers";
import MessageBox from "../common-components/message-box";
import { toast } from "../common-components/custom-styled-toast-container";
import { ThemedLink, ThemedHorizontalRule } from "../common-components/themed-components";

class Timeout extends Component {
  constructor(props) {
    super(props);

    this.queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    this.state = {
      password: "",
      httpRequestInFlight: false
    };

    this.recaptchaRef = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?
      React.createRef() :
      {
        current : {
          getValue : () => "",
          reset : () => "",
          execute: this.onSubmit
        }
      };
  }

  receiveUpdateFromForm = formState => {
    this.setState({
      password: formState.password
    });
  };

  onSubmit = event => {
    const password = this.state.password;
    const email = this.emailFromSession;

    const recaptchaValue = this.recaptchaRef.current.getValue();
    this.recaptchaRef.current.reset();

    this.setState({
      httpRequestInFlight: true
    });

    axios({
      method: "POST",
      url: `/login${this.props.location.search}`,  //it just needs the same thing as the login does
      data: qs.stringify({
        email,
        password,
        recaptchaValue
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(({ data }) => {
        if (data.err) {
          this.setState({
            httpRequestInFlight: false
          });
          toast(<MessageBox message={data.err.message} messageType="error" />);
        } else {
          window.location = `${this.queryParams.redirect_uri}?${qs.stringify({
            code: data.authCode,
            state: this.queryParams.state
          })}`;
        }
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.httpRequestInFlight !== nextState.httpRequestInFlight) {
      return true;
    }
    return false;
  }

  render() {
    const forwardedQueryParams = qs.stringify(this.queryParams);

    return (
      <Fragment>
        <MainContentContainer>
          {/* Q: Why is this entire thing wrapped in Get? It looks like only the h1 depends on the email from session */}
          {/* A: Because the onSubmit handler depends on this.emailFromSession. Don't want the form to be interactable before it loads */}
          <Get url="/getDataFromSession" params={{ attribute: "email" }}>
            {(error, response) => {
              if (error) {
                toast(
                  <MessageBox message="Malformed session detected. Please try logging in again." messageType="error" />
                );
                return (
                  <Redirect
                    to={{
                      pathname: "/login",
                      search: forwardedQueryParams
                    }}
                  />
                );
              } else if (response) {
                this.emailFromSession = response.data.email;
                return (
                  <Fragment>
                    <CommonHeader>
                      <h1>{getContentString("timeoutLogin.heading", { email: maskEmail(this.emailFromSession) })}</h1>
                      <p>{getContentString("timeoutLogin.description")}</p>
                    </CommonHeader>
                    <section>
                      <TimeoutForm
                        id="timeout"
                        onSubmit={this.onSubmit}
                        formStateListener={this.receiveUpdateFromForm}
                        isSubmissionProcessing={this.state.httpRequestInFlight}
                        buttonText={getContentString("timeoutLogin.buttonText")}
                        recaptchaRef={this.recaptchaRef}
                      />
                      <SectionFooter>
                        <p style={{ marginBottom: "15px" }}>
                          <ThemedLink
                            href={`/forgotPassword?${forwardedQueryParams}`}
                            data-tracking-id="forgotPassword-link"
                          >
                            {getContentString("timeoutLogin.forgotPasswordText")}
                          </ThemedLink>
                        </p>
                        {process.env.REACT_APP_BRAND === "jlp" || process.env.REACT_APP_BRAND === "britline" ? (
                          <ThemedHorizontalRule style={{ marginTop: "60px" }} />
                        ) : null}
                        <p>
                          {getContentString("timeoutLogin.logoutText")}{" "}
                          <ThemedLink href={`/logout?${forwardedQueryParams}`} data-tracking-id="logout-link">
                            {getContentString("timeoutLogin.logoutLinkText")}
                          </ThemedLink>
                        </p>
                      </SectionFooter>
                    </section>
                  </Fragment>
                );
              }

              return null;
            }}
          </Get>
        </MainContentContainer>
        <CommonFooter />
      </Fragment>
    );
  }
}

export default Timeout;
