class RedirectMappings {  
  // Here's some of the redirect_urls from dev/uat/prod. We must support
  // all prod, but just some of uat and dev as there are too many 
  // consumer sites, but just one corp site.
  // E.g. 3 production  (taken from graylog)
  //   https://transfer.xe.com/account/login/home
  //   https://transfer.xe.com/signup/personal/step1
  //   https://corporate-registration.xe.com
  //   And hopefully never used again -
  //   https://1.transfer.xe.com/signup/business
  //   And others to ignore
  //   https://xera.xe.com, https://help.xe.com
  //
  // Lots for UAT: 
  //   https://transfer.xe.com.uat.poc/account/login/home
  //   https://transfer.xe.com.test.poc/account/login/home
  //   https://transfer.xe.com.uat.poc/signup/personal/step1
  //   https://transfer.xe.com.test.poc/signup/personal/step1
  //   https://staging-corporate-registration.xe.com
  //   https://1.transfer.xe.com.uat.poc/signup/business
  //   https://1.transfer.xe.com.nzlocal8/signup/business
  //
  // For DEV:
  //   https://transfer.xe.com.nzlocal:9443/account/login/home
  //   https://transfer.xe.com.nzlocal:9443/signup/personal/step1
  //   https://transfer.xe.com.nzlocal:9443/signup
  //   https://integration-secure-website.xe.com/corporate
  //   https://1.transfer.xe.com.nzlocal:7443/signup/business
  static get PrivateCorporateMappings() {
    return {
      //  map this url                                                to that one.
      // prod
      "https://transfer.xe.com/account/login/home"                 : "https://corporate-registration.xe.com",
      "https://transfer.xe.com/signup/personal/step1"              : "https://corporate-registration.xe.com",
      "https://corporate-registration.xe.com"                      : "https://personal-registration.xe.com",            // mapped to new personal Reg
      "https://personal-registration.xe.com"                       : "https://corporate-registration.xe.com",
	

      // uat
      "https://transfer.xe.com.uat.poc/account/login/home"         : "https://staging-corporate-registration.xe.com",
      "https://transfer.xe.com.uat.poc/signup/personal/step1"      : "https://staging-corporate-registration.xe.com",
      "https://staging-corporate-registration.xe.com"              : "https://staging-personal-registration.xe.com",    // mapped to new personal Reg
      "https://staging-personal-registration.xe.com"               : "https://staging-corporate-registration.xe.com",

      // sandbox
      "https://transfer.xe.com.test.poc/account/login/home"        : "https://sandbox-corporate-registration.xe.com",
      "https://transfer.xe.com.test.poc/signup/personal/step1"     : "https://sandbox-corporate-registration.xe.com",
      "https://sandbox-corporate-registration.xe.com"              : "https://sandbox-personal-registration.xe.com",
      "https://sandbox-personal-registration.xe.com"               : "https://sandbox-corporate-registration.xe.com",
      // dev
      "https://transfer.xe.com.nzlocal:9443/account/login/home"    : "https://integration-corporate-registration.xe.com",
      "https://transfer.xe.com.nzlocal:9443/signup/personal/step1" : "https://integration-corporate-registration.xe.com",
      "https://integration-corporate-registration.xe.com"          : "https://integration-personal-registration.xe.com",  // mapped to new personal Reg
      "https://transfer.xe.com.dev.poc/account/login/home"         : "https://integration-corporate-registration.xe.com",
      "https://transfer.xe.com.dev.poc/signup/personal/step1"      : "https://integration-corporate-registration.xe.com", 
	    "https://integration-personal-registration.xe.com"		       : "https://integration-corporate-registration.xe.com",
    };
  }

  // As above but map to 'login/home' as XEMT will already have an initial account
  static get PrivateCorporateLoginMappings() {
    return {
      ... RedirectMappings.PrivateCorporateMappings,
     
      // Override with
      // map this url                                                to that one.
      // prod
      "https://corporate-registration.xe.com"                      : "https://transfer.xe.com/account/login/home",
      // uat
      "https://staging-corporate-registration.xe.com"              : "https://transfer.xe.com.uat.poc/account/login/home",
      // sandbox
      "https://sandbox-corporate-registration.xe.com"              : "https://transfer.xe.com.test.poc/account/login/home",
      // dev
      "https://integration-corporate-registration.xe.com"          : "https://transfer.xe.com.nzlocal:9443/account/login/home",
    };
  }

  static IsMTRedirect(redirect) {
    return redirect in RedirectMappings.PrivateCorporateMappings;
  }
}

export default RedirectMappings;