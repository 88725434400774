
import React from "react";
import Interpolate from "react-interpolate-component";
import styled from "styled-components";
import { getContentString } from "../branding";
import { ThemedLink} from "../common-components/themed-components";

const DisclosureContainer = styled.div`
    padding-top:1.5rem;
`;

const XeRecaptchaDisclosure = ()=>{
  const {privacyText, privacyDestination} = getContentString("reCAPTCHA.links")[0];
  const {termsOfServiceText, termsOfServiceDestination} = getContentString("reCAPTCHA.links")[1];

  if (process.env.REACT_APP_BRAND==="xe") {
    return (
      <DisclosureContainer>
        <Interpolate
          with={{
            privacyPolicy: <ThemedLink href={privacyDestination}>{privacyText}</ThemedLink>,
            termsOfService:<ThemedLink href={termsOfServiceDestination}>{termsOfServiceText}</ThemedLink>
          }}
          component='p'
        >
          {getContentString("reCAPTCHA.disclosureText")}
        </Interpolate>
      </DisclosureContainer>
    );
  }
  
  return null;
  
};

export default XeRecaptchaDisclosure;