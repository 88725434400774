import ConsentManager from "./consent-manager";
import CookieUtil from "js-cookie";
import isUUID from "validator/lib/isUUID";
import uuid from "uuid/v4";
/**
 * README
 *
 * Anything that references window.analytics is Segment, and anything referencing window.amplitude is Amplitude (obviously)
 * Mapping from Segment's API to Amplitude's: https://developers.amplitude.com/docs/segment-amplitude-integration#mapping-between-segments-api-and-amplitudes-api
 * About Segment's JS API: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/
 *
 * Important note: Amplitude is always loaded, regardless of whether or not someone consents to analytics. We need to track interactions with the Consent Manager somehow right?
 */
class Analytics {
  isSegmentBeingUsed = false;

  initializeAmplitude(amplitudeApiKey, consentManager = null) {
    this.amplitudeLoaded = new Promise(resolve => {
      /* eslint-disable */
      // prettier-ignore
      (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
      ;r.type="text/javascript"
      ;r.integrity="sha384-MBHPie4YFudCVszzJY9HtVPk9Gw6aDksZxfvfxib8foDhGnE9A0OriRHh3kbhG3q"
      ;r.crossOrigin="anonymous";r.async=true
      ;r.src="https://cdn.amplitude.com/libs/amplitude-8.16.1-min.gz.js"
      ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){console.log(
      "[Amplitude] Error: could not load SDK")}};var s=t.getElementsByTagName("script"
      )[0];s.parentNode.insertBefore(r,s);function i(e,t){e.prototype[t]=function(){
      this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
      var o=function(){this._q=[];return this};var a=["add","append","clearAll",
      "prepend","set","setOnce","unset","preInsert","postInsert","remove"];for(
      var c=0;c<a.length;c++){i(o,a[c])}n.Identify=o;var l=function(){this._q=[]
      ;return this};var u=["setProductId","setQuantity","setPrice","setRevenueType",
      "setEventProperties"];for(var p=0;p<u.length;p++){i(l,u[p])}n.Revenue=l;var d=[
      "init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut",
      "setVersionName","setDomain","setDeviceId","enableTracking",
      "setGlobalUserProperties","identify","clearUserProperties","setGroup",
      "logRevenueV2","regenerateDeviceId","groupIdentify","onInit","onNewSessionStart"
      ,"logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId",
      "getDeviceId","getUserId","setMinTimeBetweenSessionsMillis",
      "setEventUploadThreshold","setUseDynamicConfig","setServerZone","setServerUrl",
      "sendEvents","setLibrary","setTransport"];function v(t){function e(e){
      t[e]=function(){t._q.push([e].concat(Array.prototype.slice.call(arguments,0)))}}
      for(var n=0;n<d.length;n++){e(d[n])}}v(n);n.getInstance=function(e){e=(
      !e||e.length===0?"$default_instance":e).toLowerCase();if(
      !Object.prototype.hasOwnProperty.call(n._iq,e)){n._iq[e]={_q:[]};v(n._iq[e])}
      return n._iq[e]};e.amplitude=n})(window,document);
      /* eslint-enable */

      // About Amplitude load options: https://amplitude.github.io/Amplitude-JavaScript/#amplitudeclientinit
      var amplitudeOptions = { includeReferrer: true, includeUtm: true };
	  if (consentManager)
		  consentManager.onLoad(() => {
			  if(window.xeSession && window.xeSession.isGeoEurope === true && !consentManager.isConsentedToCategory("performance"))
				amplitudeOptions['disableCookies'] = true;		  

			  window.amplitude
				.getInstance()
				.init(amplitudeApiKey, null, amplitudeOptions, () => resolve());
		  });
	  else
		  window.amplitude
			.getInstance()
			.init(amplitudeApiKey, null, amplitudeOptions, () => resolve());
    });
  }

  initializeSegment(segmentWriteKey, enabledSegmentIntegrations) {
    this.isSegmentBeingUsed = true;
    this.segmentLoaded = new Promise(resolve => {
      // validate segment integrations
      fetch(`https://cdn.segment.com/v1/projects/${segmentWriteKey}/integrations`)
        .then(res => {
          if (!res.ok) {
            throw new Error("Unable to fetch integrations from Segment");
          }
          return res.json();
        })
        .then(knownSegmentIntegrations => {
          for (const consentedIntegration of enabledSegmentIntegrations) {
            if (
              !knownSegmentIntegrations.find(
                segmentIntegration => segmentIntegration.name === consentedIntegration
              )
            ) {
              //throw new Error(`${consentedIntegration} is not a registered integration in Segment`);
              console.warn(`${consentedIntegration} is not a registered integration in Segment`);
            }
          }
          /* eslint-disable */
          // prettier-ignore
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
        }}();
          /* eslint-enable */

          // About Segment load options: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#load-options
          const segmentIntegrations = { All: false };
          for (const consentedIntegration of enabledSegmentIntegrations) {
            segmentIntegrations[consentedIntegration] = true;
          }
          window.analytics.load(segmentWriteKey, {
            integrations: segmentIntegrations,
          });
          window.analytics.ready(() => resolve());
        });
    });
  }

  /**
   * Performs the "track event" operation in Segment and Amplitude
   * Relevant docs:
   * https://developers.amplitude.com/docs/tracking-events
   * https://segment.com/docs/connections/spec/track/
   *
   * @param {string} eventName Name of the event
   * @param {object} eventProperties Properties of the event
   * @param {{ignoreConsent: boolean}} options options for this call
   * @param {{useSendBeacon: boolean}} options force use of 'beacon' to send event.
   *        Recommended if sending event before setting window.location
   */
  trackEvent(eventName, eventProperties, { ignoreConsent = true, useSendBeacon = false } = {}) {

    this.amplitudeLoaded.then(() => {
      if ( 
        process.env.REACT_APP_ANALYTICS_ENV !== "DISABLED" && (ignoreConsent)
      ) {
        const userAgent = navigator.userAgent.toLowerCase();
        const isiPhone = userAgent.indexOf("iphone") !== -1 || userAgent.indexOf("ipad") !== -1;
        // Only use beacon for iphone.
        if (useSendBeacon && isiPhone) {
          window.amplitude.getInstance().setTransport("beacon");
        }
        window.amplitude.getInstance().logEvent(eventName, eventProperties);
	  }
    });
    if (this.isSegmentBeingUsed)
      this.segmentLoaded.then(() => {
        if (
          process.env.REACT_APP_ANALYTICS_ENV !== "DISABLED" && 
          eventName !== "Consent State Changed"
        ) {		  
          window.analytics.track(eventName, eventProperties);
        }
      });
  }

  /**
   * Performs the "identify user" operation in Segment and Amplitude.
   * Relevant docs:
   * https://developers.amplitude.com/docs/setting-user-properties
   * https://segment.com/docs/connections/spec/identify/
   *
   * @param {object} traits Object full of traits for this user
   * @param {{ignoreConsent: boolean}} options options for this call
   * @param {string} userId Known user ID. Use this only if you actually have the primary identifier for the user. Otherwise just leave it undefined.
   */
  identifyUser(traits, { ignoreConsent = false } = {}, userId) {
    this.amplitudeLoaded.then(() => {
      if (
        process.env.REACT_APP_ANALYTICS_ENV !== "DISABLED" && 
        (ConsentManager.isConsentedToCategory("performance") ||
        ConsentManager.isConsentedToCategory("compliance") ||
        ignoreConsent)
      ) {		  
        if (userId) {
          window.amplitude.getInstance().setUserId(userId);
        }
        window.amplitude.getInstance().setUserProperties(traits);
      }
    });    
	if (this.isSegmentBeingUsed)
      this.segmentLoaded.then(() => {
        if (process.env.REACT_APP_ANALYTICS_ENV !== "DISABLED") {
          window.analytics.identify(userId, traits);
        }
      });	  
  }

  /**
   * Sends a page view event in Segment and Amplitude
   * Relevant docs:
   * https://segment.com/docs/connections/spec/page/
   * Note that document.title is purposefully omitted because it tends to be a garbage value in the xe.com SPA. It's not very valuable for analytics overall.
   *
   * @param {string} name The page's name
   * @param {object} properties Properties of the page (such as path, referrer). Many of these are already filled in by default and don't need to be supplied
   * @param {{ignoreConsent: boolean}} options options for this call
   */
  pageView(name, properties, { ignoreConsent = false } = {}) {
    const trailingSlashRegex = /\/$/;
    const localePathRegex = /^(\/(en|fr|es|de|pt|it|sv|zh-CN|zh-HK|ja|ar))/;
    const localeMatch = window.location.pathname.match(localePathRegex);

    let trimmedPath = window.location.pathname
      .replace(localePathRegex, "")
      .replace(trailingSlashRegex, "");
    if (trimmedPath === "") {
      trimmedPath = "/";
    }

    // Attempt to extract ct aPosition from the URL. It isn't straightforward because it's encoded inside the "state" qs param
    let ctaPosition = null;
    try {
      const qsParams = new URLSearchParams(window.location.search);
      const state = qsParams.get("state");
      if (state) {
        const decoded = decodeURI(state);
        ctaPosition = (new URL(decoded)).searchParams.get("ctaPosition");
      }
    } catch (error) {
      console.error("Something went wrong while trying to extract ctaPosition: ", error);
    }

    const amendedProperties = {
      path: trimmedPath,
      referrer: document.referrer,
      search: window.location.search,
      url: window.location.href,
      hostname: window.location.hostname,
      locale: localeMatch ? localeMatch[2] : "en",
      xeid: CookieUtil.get("xeid"),
      ...(ctaPosition && { ctaPosition }),
      ...properties,
    };

    this.amplitudeLoaded.then(() => {
      if (
        ConsentManager.isConsentedToCategory("performance") ||
        ConsentManager.isConsentedToCategory("compliance") ||
        ignoreConsent
      ) {		  
        window.amplitude.getInstance().logEvent(`${(name !== null && name !== undefined) ? name : "Page"} Viewed`, amendedProperties);
      }
    });	
    if (this.isSegmentBeingUsed) {
      this.segmentLoaded.then(() => {
        window.analytics.page(name, amendedProperties);
      });
    }
  }
  setXeid() {
    if (!CookieUtil.get("xeid") || !isUUID(CookieUtil.get("xeid"))) {
      if (ConsentManager.isConsentedToCategory("marketing")) {
        const xeid = uuid();
        // Note that the domain logic matches what's used in login() (server/app.js)
        CookieUtil.set("xeid", xeid, {
          expires: 365 * 20,
          domain: window.location.hostname.split(".").slice(-2).join(".")
        });
      }
    }
  }
}

const analyticsInstance = new Analytics();

export default analyticsInstance;
