import React, { Component, Fragment } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { toast } from "react-toastify";

import XeThemeV1 from "@xe/theme-xe";
import GlobalStyles from "@xe/styles-global";

import { theme as accountsTheme } from "./branding";
import CustomStyledToastContainer from "./common-components/custom-styled-toast-container";
import ConfirmSignup from "./auth-components/confirm-signup";
import Login from "./auth-components/login";
import Signup from "./auth-components/signup";
import ChangePassword from "./auth-components/change-password";
import ForgotPassword from "./auth-components/forgot-password";
import ConfirmForgotPassword from "./auth-components/confirm-forgot-password";
import MigrationActionRequired from "./auth-components/migration-action-required";
import MigrationPasswordNotice from "./auth-components/migration-password-notice";
import SelectAccountType from "./auth-components/select-account-type";
import Timeout from "./auth-components/timeout";
import ContinueAs from "./auth-components/continue-as";
import PolymorphicError from "./error-components/polymorphic-error";
import Analytics from "./common-components/cookie-consent/analytics";

const AccountsGlobalStyle = createGlobalStyle`
  ${({ theme }) => `
    ${theme.fontFaceRules ? theme.fontFaceRules : ""}

    body {
      background-color: ${theme.inverseBackgroundColor}
      color: ${theme.textColor};
      ${theme.fontFamily ? `font-family: ${theme.fontFamily};` : ""}
      font-size: ${theme.defaultFontSize};
    }

    h1 {
      color: ${theme.headingColor};
      ${theme.headingFontFamily ? `font-family: ${theme.headingFontFamily};` : ""}
      font-size: ${theme.headingFontSize};
      font-weight: ${theme.headingFontWeight};
    }

    footer {
      font-size: ${theme.secondaryFontSize};
    }

    ${theme.extraFormStyles ? theme.extraFormStyles : ""}
  `}
`;

class App extends Component {
  constructor(props) {
    super(props);

    this.history = createBrowserHistory();
    this.unlisten = this.history.listen((location, action) => {
      /*
       * The only "replace" type navigations that happen in accounts-ui are the ones that occur when a user tries to
       * directly navigate to /confirm or /confirmForgotPassword and then is forcefully kicked out with a toast that
       * says "you tried to reach this page in an invalid flow". Those toasts are fired by /confirm and
       * /confirmForgotPassword, so we don't want to dismiss them immediately after they are fired (race condition).
       */
      if (action !== "REPLACE") {
        toast.dismiss(); // Dismiss all current toasts
        if (process.env.REACT_APP_ANALYTICS_ENV !== "DISABLED") {
          Analytics.pageView();
        }
      }
    });
  }
  async componentDidMount() {
    Analytics.pageView();
  }

  // This will probably never execute in practice, after all this is the root component. Implemented for completeness.
  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    return (
      <ThemeProvider theme={XeThemeV1}>
        <ThemeProvider theme={accountsTheme}>
          <Fragment>
            <GlobalStyles />
            <AccountsGlobalStyle />
            <CustomStyledToastContainer />
            <Router history={this.history}>
              <Switch>
                <Route path="/signup" exact component={Signup} />
                <Route path="/login" exact component={Login} />
                <Route path="/confirm" exact component={ConfirmSignup} />
                <Route path="/migrationActionRequired" exact component={MigrationActionRequired} />
                <Route path="/migrationPasswordNotice" exact component={MigrationPasswordNotice} />
                { (process.env.REACT_APP_BRAND === "xe") && <Route path="/selectAccountType" exact component={SelectAccountType} />}
                <Route path="/changePassword" exact component={ChangePassword} />
                <Route path="/timeout" exact component={Timeout} />
                <Route path="/continueAs" exact component={ContinueAs} />
                { (process.env.REACT_APP_SHOW_APOLLO === "true" && process.env.REACT_APP_BRAND === "xe") && <Route path="/forgotPassword" render={() => (window.location = `/forgotPassword${this.history.location.search}`)} /> }
                { (process.env.REACT_APP_SHOW_APOLLO !== "true" || process.env.REACT_APP_BRAND !== "xe") && <Route path="/forgotPassword" exact component={ForgotPassword} /> }
                { (process.env.REACT_APP_SHOW_APOLLO !== "true" || process.env.REACT_APP_BRAND !== "xe") && <Route path="/confirmForgotPassword" exact component={ConfirmForgotPassword} /> }
                <Route path="/changeEmail" render={() => (window.location = `/changeEmail${this.history.location.search}`)} />
                <Route path="/badRequest" exact render={props => <PolymorphicError {...props} errorCode={400} />} />
                <Route path="/internalServerError" exact render={props => <PolymorphicError {...props} errorCode={500} />} />
                <Route render={props => <PolymorphicError {...props} errorCode={404} />} />
              </Switch>
            </Router>
          </Fragment>
        </ThemeProvider>
      </ThemeProvider>
    );
  }
}

export default App;
