import React, { Fragment } from "react";
import styled from "styled-components";
import qs from "qs";

import CommonHeader from "../common-components/common-header";
import CommonFooter from "../common-components/common-footer";
import { MainContentContainer } from "../common-components/containers";
import Error400Flags from "./images/error-400-flags.png";
import Error500Flags from "./images/error-500-flags.png";
import Error404Flags from "./images/error-404-flags.png";
import { ThemedLink } from "../common-components/themed-components";
import { getContentString } from "../branding";

const ErrorImg = styled.img`
  width: 100%;
  margin-bottom: 20px;
`;

const ErrorMessage = styled.p`
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
`;

const ReturnMessage = styled.p`
  text-align: center;
`;

const getErrorPieces = errorCode => {
  switch (errorCode) {
    case 400:
      return {
        heading: "Bad Request",
        desc: "Your request parameters were not allowed.",
        imgSrc: Error400Flags
      };
    case 500:
      return {
        heading: "Internal Server Error",
        desc: "The server could not process your request.",
        imgSrc: Error500Flags
      };
    case 404:
      return {
        heading: "Not Found",
        desc: "The page you requested could not be found. Please double-check that the URL was spelled correctly.",
        imgSrc: Error404Flags
      };
    default:
      throw new Error("Unsupported error code");
  }
};

const PolymorphicError = ({ errorCode, location }) => {
  const { heading, desc, imgSrc } = getErrorPieces(errorCode);

  if (errorCode !== 404) {
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    console.error("Additional error page details: ", queryParams.errorMsg);
  }

  const { text: homeLinkText, destination: homeLinkDest } = getContentString("homepageLink");

  return (
    <Fragment>
      <MainContentContainer>
        <CommonHeader>
          <h1>{heading}</h1>
          <p>{desc}</p>
        </CommonHeader>
        <section>
          <ErrorImg src={imgSrc} />
          <ErrorMessage>{`Error code: ${errorCode}`}</ErrorMessage>
          <ReturnMessage>
            Return to <ThemedLink href={homeLinkDest}>{homeLinkText}</ThemedLink>
          </ReturnMessage>
        </section>
      </MainContentContainer>
      <CommonFooter />
    </Fragment>
  );
};

export default PolymorphicError;
