import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { PasswordInput } from "@xe/molecule-form-input";

import { ThemedButton, ThemedSpinner } from "../common-components/themed-components";

class TimeoutForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittable: false,
      password: {
        value: "",
        valid: null
      }
    };
  }

  updateField = (fieldName, fieldState) => {
    this.setState(
      {
        [fieldName]: {
          value: fieldState.value,
          valid: fieldState.valid
        }
      },
      () => {
        this.props.formStateListener({
          password: this.state.password.value
        });
        this.checkIsFormSubmittable();
      }
    );
  };

  checkIsFormSubmittable = () => {
    if (this.state.password.valid) {
      this.setState({ isSubmittable: true });
    } else {
      this.setState({ isSubmittable: false });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.isSubmittable !== nextState.isSubmittable ||
      this.props.isSubmissionProcessing !== nextProps.isSubmissionProcessing) {
      return true;
    }
    return false;
  }

  render() {
    const { buttonText } = this.props;
    return (
      <form
        action={this.props.action}
        id={this.props.id}
        method={this.props.method}
        onSubmit={e => {
          e.preventDefault();
          this.props.recaptchaRef.current.execute();
        }}
      >
        <PasswordInput
          autoComplete="current-password"
          required
          onUpdate={fieldState => this.updateField("password", fieldState)}
        />

        {process.env.REACT_APP_RECAPTCHA_SITE_KEY ?
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            size="invisible"
            ref={this.props.recaptchaRef}
            onChange={this.props.onSubmit}
            badge="inline"
            style={{ display: "none" }}
          /> :
          "NoCAPTCHA!"
        }

        <ThemedButton
          disabled={!this.state.isSubmittable || this.props.isSubmissionProcessing}
          type="submit"
          data-loading={this.props.isSubmissionProcessing}
        >
          {this.props.isSubmissionProcessing ? <ThemedSpinner /> : buttonText}
        </ThemedButton>
      </form>
    );
  }
}

TimeoutForm.defaultProps = {
  action: "",
  buttonText: "",
  id: null,
  method: "post",
  onSubmit: () => {},
  formStateListener: formState => {}
};

export default TimeoutForm;
