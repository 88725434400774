import React, { Component } from "react";
import styled from "styled-components";
import { VerificationCodeInput, PasswordInput } from "@xe/molecule-form-input";
import ReCAPTCHA from "react-google-recaptcha";
import { ThemedButton, ThemedSpinner } from "../common-components/themed-components";

/*
 * Q: What's with the unusual width and margin?
 * A: When I wrote this collapsing container, I ran into a very unexpected problem: you can't use overflow-y: hidden
 * and overflow-x: visible at the same time (see https://stackoverflow.com/a/6433475). I need to have overflow-y:
 * hidden because otherwise the container doesn't animate properly, and I need overflow-x: visible because otherwise
 * the focus ring on the input field gets clipped. So I came up with a workaround: force the width to be 10px wider
 * than it would normally be, and then add padding to make it visually the same width as other form inputs on the page,
 * and then re-center it with negative margin.
 */
const CollapsibleContainer = styled.div`
  ${({ isCollapsed }) => `
    overflow-y: hidden;
    overflow-x: visible;
    width: calc(100% + 10px);
    padding: 0 5px;
    margin-left: -5px;
    max-height: ${isCollapsed ? "0" : "325px"};
    visibility: ${isCollapsed ? "hidden" : "visible"};
    transition: max-height .2s linear, visibility .2s linear;
  `}
`;

class ConfirmForgotPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittable: false,
      verificationCode: {
        value: "",
        valid: null
      },
      password: {
        value: "",
        valid: null
      }
    };
  }

  updateField = (fieldName, fieldState) => {
    this.setState(
      {
        [fieldName]: {
          value: fieldState.value,
          valid: fieldState.valid
        }
      },
      () => {
        this.props.formStateListener({
          verificationCode: this.state.verificationCode.value,
          password: this.state.password.value
        });
        this.checkIsFormSubmittable();
      }
    );
  };

  checkIsFormSubmittable = () => {
    if (this.state.verificationCode.valid && this.state.password.valid) {
      this.setState({ isSubmittable: true });
    } else {
      this.setState({ isSubmittable: false });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.isSubmittable !== nextState.isSubmittable ||
      this.state.verificationCode.valid !== nextState.verificationCode.valid ||
      this.props.isSubmissionProcessing !== nextProps.isSubmissionProcessing
    ) {
      return true;
    }
    return false;
  }

  render () {
    const { newPasswordPrompt, buttonText, inputLabelText } = this.props;

    return (
      <form
        action={this.props.action}
        id={this.props.id}
        method={this.props.method}
        onSubmit={e => {
          e.preventDefault();
          var email = this.props.email;
          this.props.recaptchaRef.current.execute();
        }}
      >
        <VerificationCodeInput
          labelText={inputLabelText}
          onUpdate={fieldState => this.updateField("verificationCode", fieldState)}
          required
        />
        <CollapsibleContainer isCollapsed={!this.state.verificationCode.valid}>
          {newPasswordPrompt && (
            <p style={{ marginBottom: "20px" }}>{newPasswordPrompt}</p>
          )}
          <PasswordInput
            autoComplete="new-password"
            labelText="Password"
            inputId="password"
            inputName="password"
            isSignUpMode
            onUpdate={fieldState => this.updateField("password", fieldState)}
            required
          />
        </CollapsibleContainer>
        {process.env.REACT_APP_RECAPTCHA_SITE_KEY ?
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            size="invisible"
            ref={this.props.recaptchaRef}
            onChange={this.props.onSubmit}
            badge="inline"
            style={{ display: "none" }}
          /> :
          "NoCAPTCHA!"
        }
        <ThemedButton
          disabled={!this.state.isSubmittable || this.props.isSubmissionProcessing}
          type="submit"
          data-loading={this.props.isSubmissionProcessing}
        >
          {this.props.isSubmissionProcessing ? <ThemedSpinner /> : buttonText}
        </ThemedButton>
      </form>
    );
  }
};

ConfirmForgotPasswordForm.defaultProps = {
  action: null,
  buttonText: "",
  inputLabelText: null,
  id: null,
  method: "post",
  newPasswordPrompt: null,
  onSubmit: () => {},
  formStateListener: formState => {}
};

export default ConfirmForgotPasswordForm;
