import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import CookieBanner from "./common-components/cookie-consent/banner";
import ConsentManager from "./common-components/cookie-consent/consent-manager";
import Analytics from "./common-components/cookie-consent/analytics";
import Cookies from "js-cookie";
/*
 * Consent initialization begins
 */
Analytics.initializeAmplitude(process.env.REACT_APP_AMPLITUDE_API_KEY, ConsentManager);

let isSegmentInitialized = false;
// TODO: finalize the Segment integration categories, ESPECIALLY Segment.io itself
const integrationCategories = {
  performance: ["Segment.io", "Foo Analytics"],
  marketing: ["Iterable", "Foo Ads"],
};

if (process.env.REACT_APP_ANALYTICS_ENV !== "DISABLED") {
  window.ConsentManager = ConsentManager;
  window.Analytics = Analytics;

  const closeCookieBanner = () => {
    ReactDOM.unmountComponentAtNode(document.getElementById("cookieBannerContainer"));
  };
  window.ConsentManager.closeCookieBanner = closeCookieBanner;

  const openCookieBanner = () => {
    Analytics.trackEvent("Consent Presented", {}, { ignoreConsent: true });
    ReactDOM.render(
      <CookieBanner cleanup={closeCookieBanner} />,
      document.getElementById("cookieBannerContainer")
    );
  };
  window.ConsentManager.openCookieBanner = openCookieBanner;

  const openAdvancedCookieBanner = () => {
    Analytics.trackEvent("Consent Presented", {}, { ignoreConsent: true });
    ReactDOM.render(
      <CookieBanner initialScreen="advancedPrompt" cleanup={closeCookieBanner} />,
      document.getElementById("cookieBannerContainer")
    );
  };
  window.ConsentManager.openAdvancedCookieBanner = openAdvancedCookieBanner;

  /*
  * Leaving this in for historical reasons. At one point we were considering having
  * Segment run on xe.com but ultimately scrapped it due to a cost issue. This code
  * still lives here to show how it would be loaded if it was used in xe.com
  */
  const shouldSegmentLoad = true;

  window.optimizely = window.optimizely || [];
  window.dataLayer = window.dataLayer || [];

  ConsentManager.onLoad(() => {
    let consentedSegmentIntegrations = [];
    for (const [categoryName, integrations] of Object.entries(integrationCategories)) {
      if (ConsentManager.isConsentedToCategory(categoryName)) {
        consentedSegmentIntegrations = consentedSegmentIntegrations.concat(integrations);
      }
    }
    if (shouldSegmentLoad && consentedSegmentIntegrations.length > 0) {
      isSegmentInitialized = true;
      Analytics.initializeSegment(process.env.REACT_APP_SEGMENT_API_KEY, consentedSegmentIntegrations);
    }

    const userConsentState = {
      isConsentedToPerformance: ConsentManager.isConsentedToCategory("performance"),
      isConsentedToMarketing: ConsentManager.isConsentedToCategory("marketing"),
      isConsentedToCompliance: ConsentManager.isConsentedToCategory("compliance"),
    };

    Analytics.identifyUser(userConsentState, { ignoreConsent: true });

    window.dataLayer.push({ event: "consentStateChange", ...userConsentState });

    // See https://help.optimizely.com/Account_Settings/Enable_opt-in_options_for_Optimizely_cookies_and_local_storage
    window.optimizely.push({
      type: "optOut",
      isOptOut: !userConsentState.isConsentedToPerformance,
    });
    Analytics.setXeid();
  });

  ConsentManager.onUpdate(() => {
    let consentedSegmentIntegrations = [];
    for (const [categoryName, integrations] of Object.entries(integrationCategories)) {
      if (ConsentManager.isConsentedToCategory(categoryName)) {
        consentedSegmentIntegrations = consentedSegmentIntegrations.concat(integrations);
      }
    }
    // Remember: you can only initialize Segment one time (until the browser reloads), so we're trying to make the most of our one initialization
    // Above, if no Segment integrations are consented, we don't intialize Segment in hopes that the user will consent to one through the prompt and then we can initialize here
    if (shouldSegmentLoad && !isSegmentInitialized && consentedSegmentIntegrations.length > 0) {
      isSegmentInitialized = true;
      Analytics.initializeSegment(process.env.REACT_APP_SEGMENT_API_KEY, consentedSegmentIntegrations);
    }

    const userConsentState = {
      isConsentedToPerformance: ConsentManager.isConsentedToCategory("performance"),
      isConsentedToMarketing: ConsentManager.isConsentedToCategory("marketing"),
      isConsentedToCompliance: ConsentManager.isConsentedToCategory("compliance"),
    };

	   if (window.amplitude.getInstance() && window.amplitude.getInstance()._metadataStorage) {
		    if (window.xeSession && window.xeSession.isGeoEurope === true && !userConsentState.isConsentedToPerformance) {
		     window.amplitude.getInstance()._metadataStorage.disableCookieStorage = true;
	    } else {
		  window.amplitude.getInstance()._metadataStorage.disableCookieStorage = false;
		   }
	  }

    Analytics.identifyUser(userConsentState, { ignoreConsent: true });

    Analytics.trackEvent("Consent State Changed", {}, { ignoreConsent: true });

    window.dataLayer.push({ event: "consentStateChange", ...userConsentState });

    window.optimizely.push({
      type: "optOut",
      isOptOut: !userConsentState.isConsentedToPerformance,
    });
    Analytics.setXeid();
  });

  ConsentManager.loadConsentState(window.xeSession.isGeoEurope);
  if (ConsentManager.shouldShowConsentPrompt) {
    window.ConsentManager.openCookieBanner();
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
