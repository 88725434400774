import React, { Component, Fragment } from "react";
import { Link as ReactRouterLink, Redirect } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import { getContentString } from "../branding";
import ForgotPasswordForm from "../form-components/forgot-password-form";
import CommonHeader from "../common-components/common-header";
import CommonFooter from "../common-components/common-footer";
import { MainContentContainer, SectionFooter } from "../common-components/containers";
import MessageBox from "../common-components/message-box";
import { toast } from "../common-components/custom-styled-toast-container";
import { ThemedLink } from "../common-components/themed-components";
import ConsentManager from "../common-components/cookie-consent/consent-manager";
import XeRecaptchaDisclosure from "../brand-specific-components/xe-recaptcha-disclosure";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      authState: "forgotPassword",
      carryForwardData: null,
      httpRequestInFlight: false
    };

    this.recaptchaRef = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?
      React.createRef() :
      {
        current : {
          getValue : () => "",
          reset : () => "",
          execute: this.onSubmit
        }
      };
  }

  receiveUpdateFromForm = formState => {
    this.setState({
      email: formState.email
    });
  };


  onSubmit = event => {
    const { email } = this.state;

    const recaptchaValue = this.recaptchaRef.current.getValue();
    this.recaptchaRef.current.reset();

    if (!ConsentManager.isConsentedToCategory("compliance")) {
      ConsentManager.updateConsentState({ compliance: true });
    }

    this.setState({
      httpRequestInFlight: true
    });
    axios({
      method: "POST",
      url: `${this.props.location.pathname}${this.props.location.search}`,
      data: qs.stringify({
        email,
        recaptchaValue
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(({ data }) => {
        if (data.err) {
          this.setState({
            httpRequestInFlight: false
          });
          toast(<MessageBox message={data.err.message} messageType="error" />);
        } else {
          this.setState({
            authState: "confirmForgotPassword",
            carryForwardData: data.result
          });
        }
      })
      .catch(err => {
        this.setState({
          httpRequestInFlight: false
        });
        toast(<MessageBox message={err} messageType="error" />);
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.authState !== nextState.authState ||
      this.state.httpRequestInFlight !== nextState.httpRequestInFlight
    ) {
      return true;
    }
    return false;
  }

  render() {
    if (this.state.authState === "confirmForgotPassword") {
      return (
        <Redirect
          push
          to={{
            pathname: "/confirmForgotPassword",
            search: this.props.location.search,
            state: {
              email: this.state.email,
              confirmForgotPasswordData: this.state.carryForwardData // not presently used
            }
          }}
        />
      );
    }

    return (
      <Fragment>
        <MainContentContainer>
          <CommonHeader>
            <h1>{getContentString("forgotPassword.heading")}</h1>
            <p>{getContentString("forgotPassword.description")}</p>
          </CommonHeader>
          <section>
            <ForgotPasswordForm
              onSubmit={this.onSubmit}
              formStateListener={this.receiveUpdateFromForm}
              isSubmissionProcessing={this.state.httpRequestInFlight}
              buttonText={getContentString("forgotPassword.buttonText")}
              recaptchaRef={this.recaptchaRef}
            />
            <SectionFooter>
              <p>
                <ThemedLink
                  as={ReactRouterLink}
                  to={{ pathname: "/login", search: this.props.location.search }}
                  data-tracking-id="login-link"
                >
                  {getContentString("forgotPassword.loginText")}
                </ThemedLink>
              </p>
              <XeRecaptchaDisclosure/>
            </SectionFooter>
          </section>
        </MainContentContainer>
        <CommonFooter />
      </Fragment>
    );
  }
}

export default ForgotPassword;
