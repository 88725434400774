const colors = {
  backdropColor: "#f8f8f8",
  backgroundColor: "#fff",
  headingColor: "#141e37",
  textColor: "#141e37",
  linkColor: "#003cbe",
  link: {
    mainColor: "#003cbe"
  },
  buttonBackgroundColor: "#003cbe",
  buttonActiveBackgroundColor: "#003cbe", // FIX ME: Update this with finalized hover color
  buttonDisabledBackgroundColor: "#c8d2e6", // FIX ME: This should probably be added to the true xecom global theme
  buttonTextColor: "#fff",
  inverseBackgroundColor: "#002272",
  inverseTextColor: "#fff",
  spinnerColor1: "#b1d7fe",
  spinnerColor2: "#005cef"
};

// font-face and font-family are already defined in @xe/styles-global, so they don't need to be redefined here
// font sizes are more application-specific so i think it's fine to define them here
const typography = {
  defaultFontSize: "1.6rem",
  headingFontSize: "2rem",
  secondaryFontSize: "1.4rem",
  headingFontWeight: "bold",
  buttonFontWeight: "bold",
  buttonTextTransform: "uppercase"
};

export default {
  ...colors,
  ...typography
};
