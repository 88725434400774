/*
 * font files downloaded from https://www.fontsquirrel.com/fonts/tex-gyre-adventor
 * any fonts distributed by Font Squirrel are supposed to be free for commerical use
 * license can be read at the above URL
 */
import regularFontUrl from "../fonts/tex-gyre-adventor-regular.woff";
import boldFontUrl from "../fonts/tex-gyre-adventor-bold.woff";

const colors = {
  backdropColor: "#fff",
  backgroundColor: "#fff",
  headingColor: "#011f2a",
  textColor: "#666666",
  linkColor: "#008194",
  link: {
    mainColor: "#003cbe"
  },
  buttonBackgroundColor: "#333",
  buttonActiveBackgroundColor: "#333", // FIX ME: Update this with finalized hover color
  buttonDisabledBackgroundColor: "#ccc", // FIX ME: not official lol. andre made this colour up
  buttonGradientColor1: "#333",
  buttonGradientColor2: "#4d4d4d",
  buttonTextColor: "#fff",
  inverseBackgroundColor: "#083234",
  inverseTextColor: "#fff",
  inputTextColor: "#666",
  normalInputBorderColor: "#aaa",
  invalidInputBorderColor: "#ff0000",
  invalidInputBackgroundColor: "#fff",
  focusedInputBorderColor: "#2e3137",
  focusedInputBackgroundColor: "#fff",
  spinnerColor1: "#1fbec7",
  spinnerColor2: "#083234",
  horizontalRuleColor: "#dcdcdc"
};

const typography = {
  defaultFontSize: "1.5rem",
  headingFontSize: "2.5rem",
  secondaryFontSize: "1.5rem",
  fontFamily: [
    "TeX Gyre Adventor",
    "Arial",
    "Helvetica",
    "sans-serif"
  ].join(","),
  fontFaceRules: `
    @font-face {
      font-family: 'TeX Gyre Adventor';
      font-weight: normal;
      font-style: normal;
      font-display: swap;
      src: local('TeX Gyre Adventor'), url('${regularFontUrl}') format('woff');
    }

    @font-face {
      font-family: 'TeX Gyre Adventor';
      font-weight: bold;
      font-style: normal;
      font-display: swap;
      src: local('TeX Gyre Adventor Bold'), url('${boldFontUrl}') format('woff');
    }
  `,
  headingFontWeight: "bold",
  buttonFontWeight: "bold",
  buttonTextTransform: "uppercase",
  labelFontWeight: "bold"
};

const display = {
  headerAlignment: "center"
};

const footer = {
  footerLinksConstrictorMaxWidth: "750px",
  extraFooterLegalTextContainerStyles: `
    font-size: 1.2rem;

    .copyright {
      font-size: 2.0rem;
      color: #20c2c9;
      margin-bottom: 10px;
      margin-top: 30px;
    }
  `
};

const buttons = {
  extraButtonStyles: `
    :hover:enabled, :focus {
      background: linear-gradient(to top, ${colors.buttonGradientColor1} 0%, ${colors.buttonGradientColor2} 100%);
    }
    padding-top: 5px;
    padding-bottom: 5px;
  `
};

export default {
  ...colors,
  ...typography,
  ...display,
  ...footer,
  ...buttons
};
