import React, { useState } from "react";
import styled from "styled-components";

// Horizontal tabs
const StyledDiv = styled.div`
display: flex;
flex-direction: row;
align-items:stretch;
gap:5px;
`;

// Vertical flowing box
const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 9.12px;
  border: 1px solid #DDDDDD;
  padding: 10px;
  flex:1;
  &:hover {
    border: 1px solid #EEEEEE;
  }
  ${({ active }) =>
    active &&
    `background: #F2F7FE;
  `}
`;

const SmallText = styled.div`
  font-size:1.4rem;
`;

const MediumText = styled.div`
  font-size:1.8rem;
  font-weight:500;
`;

// A very simple card component 
//
// props:
//   tabs = {id:1, title:'my tab name', img, heading, text}
//   onSelect = pass 'title' selected to callback
//   defaultTab = default tab title to select
//   
function CardGroup(props) {
  const [active, setActive] = useState(props.defaultTab);
  return (
    <>
    <StyledDiv>
      {
        props.tabs.map(({id, title, img, text}, ignore) => (
          <StyledCard
            key={title}
            active={active === title}
            onClick={() => {
              // Only call callback if tab changed
              if (active !== title) {
                props.onSelect(title);
                setActive(title);
              }
            }}
          >
            <img src={img} width="25%"/>
            <MediumText>{title}</MediumText>
            <SmallText>{text}</SmallText>
          </StyledCard>
        ))
      }
    </StyledDiv>
    </>
  );
}

export {CardGroup};
