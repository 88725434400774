import { sprintf } from "sprintf-js";
import xeContent from "./content/xe.json";

function getContentStringFunction(defaultBrand, brand) {
  const contentStrings = {
    xe: xeContent
  };

  if (brand !== defaultBrand) {
    contentStrings[brand] = require(`./content/${brand}.json`);
  }

  return (key, args = null) => {
    const contentString = contentStrings[brand][key] || contentStrings[defaultBrand][key] || key;

    if (!args) {
      return contentString;
    }

    return sprintf(contentString, args);
  }
}

export default getContentStringFunction;
