import xeTheme from "./themes/xe";
import britlineTheme from "./themes/britline";
import jlpTheme from "./themes/jlp";
import libroTheme from "./themes/libro";

const themes = {
  xe: xeTheme,
  britline: britlineTheme,
  jlp: jlpTheme,
  libro: libroTheme
};

export default themes[process.env.REACT_APP_BRAND];
