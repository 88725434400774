// This config is in here because Create React App does not allow referencing relative modules outside of src
const defaultBrand = "xe";
const allowedBrandValues = ["xe", "jlp", "britline", "libro"];

const brand = allowedBrandValues.indexOf(process.env.REACT_APP_BRAND) > -1 ? process.env.REACT_APP_BRAND : defaultBrand;

export {
  allowedBrandValues,
  brand,
  defaultBrand
};
