import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import CookieUtil from "js-cookie";
import { EmailInput, PasswordInput } from "@xe/molecule-form-input";
import { ThemedButton, ThemedSpinner } from "../common-components/themed-components";
import { withRouter } from "react-router-dom";
import {TabGroup} from "../common-components/tabs";
import RedirectMappings from "../common-components/redirectMappings"

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.prefillEmail = CookieUtil.get("signupPrefillEmail") || "";

    this.state = {
      isSubmittable: false,
      email: {
        value: "",
        valid: null // null means "not yet validated"
      },
      password: {
        value: "",
        valid: null
      },
    };
  }

  updateField = (fieldName, fieldState) => {
    this.setState(
      {
        [fieldName]: {
          value: fieldState.value,
          valid: fieldState.valid
        }
      },
      () => {
        this.props.formStateListener({
          email: this.state.email.value,
          password: this.state.password.value
        });
        this.checkIsFormSubmittable();
      }
    );
  };

  // Choose initial tab based on redirect uri
  defaultTab = () => {
    const qsParams = new URLSearchParams(window.location.search);
    const redirect = qsParams.get("redirect_uri");
    const tab = redirect.includes("corporate-registration") ? "Business" : "Personal";

    return tab;
  }

  
  // Only show tab for XE and only when the redirect_url is one we're expecting.
  showTabs = () => {
    const qsParams = new URLSearchParams(window.location.search);
    const redirect = qsParams.get("redirect_uri");
    return process.env.REACT_APP_BRAND === "xe" && redirect in RedirectMappings.PrivateCorporateMappings;
  }

  // Toggle the redirect_url (we can toggle as we only have 2 tabs). As we know
  // exactly what redirect_url was initially used the tab mapping is safe.
  toggleTab = (selectedTab) => {
    const qsParams = new URLSearchParams(window.location.search);
    const redirect = qsParams.get("redirect_uri");
    
    qsParams.set("redirect_uri", RedirectMappings.PrivateCorporateMappings[redirect]);

    // Update history
    this.props.history.push({pathname:"signup", search : qsParams.toString()});
  }

  checkIsFormSubmittable = () => {
    if (this.state.email.valid && this.state.password.valid) {
      this.setState({ isSubmittable: true });
    } else {
      this.setState({ isSubmittable: false });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.isSubmittable !== nextState.isSubmittable ||
      this.props.isSubmissionProcessing !== nextProps.isSubmissionProcessing
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { buttonText } = this.props;
    const tabset = [{id:1, title:"Personal"}, {id:2, title:"Business"}];

    return (
      <form
        action={this.props.action}
        id={this.props.id}
        method={this.props.method}
        onSubmit={e => {
          e.preventDefault();
          this.props.onCheckRecaptchaAndSubmit(this.state.email.value, this.props.recaptchaRef);
        }}
      >
        {/** If you ever find that this child is rerendering too often, change the anonymous arrow function in
        onUpdate to a class property for a quick win. JS makes a new anonymous function every time this form's render
        function gets called, so it causes an unnecessary prop update. But the anonymous function code looks cleaner.*/}

        {this.showTabs() ? (
          <div><TabGroup tabs={tabset} onSelect={this.toggleTab} defaultTab={this.defaultTab()}></TabGroup><p class="padded"/></div>) : null}
          
        <EmailInput
          labelText={this.props.emailLabel}
          autoComplete="username email"
          required
          showCheckmark
          onUpdate={fieldState => this.updateField("email", fieldState)}
          value={this.prefillEmail}
        />

        <PasswordInput
          labelText={this.props.passwordLabel}
          autoComplete="new-password"
          required
          isSignUpMode
          onUpdate={fieldState => this.updateField("password", fieldState)}
        />

        {process.env.REACT_APP_RECAPTCHA_SITE_KEY && !this.props.disableRecaptcha ?
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            size="invisible"
            ref={this.props.recaptchaRef}
            onChange={this.props.onSubmit}
            badge="inline"
            style={{ display: "none" }}
          /> :
          "NoCAPTCHA!"
        }

        <div style={{ margin: "30px 0" }}>
          <ThemedButton
            disabled={!this.state.isSubmittable || this.props.isSubmissionProcessing}
            type="submit"
            data-loading={this.props.isSubmissionProcessing}
          >
            {this.props.isSubmissionProcessing ? <ThemedSpinner /> : buttonText}
          </ThemedButton>
        </div>
      </form>
    );
  }
}

SignupForm.defaultProps = {
  action: "",
  buttonText: "",
  id: null,
  disableRecaptcha: false,
  method: "post",
  onSubmit: () => {},
  formStateListener: formState => {}
};

export default withRouter(SignupForm);
