const ERROR_MESSAGE_CONSTANTS = {
  INVALID_EMAIL_PASSWORD: "Incorrect username or password.",
  USER_NOT_FOUND: "User does not exist.",
  LIMIT_EXCEEDED: "You have requested for too many verification codes. Please try again in an hour.", 
  CONFIRMED_INVALID_PASSWORD: "Confirmation succeeded, but the password was incorrect. Please try to log in again.",
  UNKNOWN_ERROR: "Something bad happened."
};

const COGNITO_ERROR_MAPPING = {
  UserNotFoundException: {
    default: ERROR_MESSAGE_CONSTANTS.INVALID_EMAIL_PASSWORD,
    "/forgotPassword": ERROR_MESSAGE_CONSTANTS.USER_NOT_FOUND
  },
  LimitExceededException: {
    default: ERROR_MESSAGE_CONSTANTS.LIMIT_EXCEEDED,
    "/forgotPassword": ERROR_MESSAGE_CONSTANTS.LIMIT_EXCEEDED
  },
  NotAuthorizedException: {
    default: ERROR_MESSAGE_CONSTANTS.INVALID_EMAIL_PASSWORD,
    "/confirm": ERROR_MESSAGE_CONSTANTS.CONFIRMED_INVALID_PASSWORD
  }
};

export default function processError(error, endpoint = "default") {
  if (COGNITO_ERROR_MAPPING[error.code] && COGNITO_ERROR_MAPPING[error.code][endpoint]) {
    return COGNITO_ERROR_MAPPING[error.code][endpoint];
  } else if (COGNITO_ERROR_MAPPING[error.code]) {
    return COGNITO_ERROR_MAPPING[error.code]["default"];
  }
  return ERROR_MESSAGE_CONSTANTS.UNKNOWN_ERROR;
}
