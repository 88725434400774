import React, { Component } from "react";
import styled from "styled-components";
import axios from "axios";
import qs from "qs";
import Countdown from "react-countdown";
import maskEmail from "@xe/util-mask-email";
import ReCAPTCHA from "react-google-recaptcha";
import MessageBox from "./message-box";
import { toast } from "./custom-styled-toast-container";

import { ThemedLink, ThemedSpinner } from "./themed-components";

const ButtonMasqueradingAsLink = styled(ThemedLink).attrs({ type: "button" })`
  ${({ theme }) => `
    appearance: none;
    border: none;
    padding: 0;
    background: none;
    color: ${theme.linkColor}; // Have to set color here because ThemedLink (XeLink) predicates it on :link
    text-decoration: underline; // Same reasoning as above
    min-height: 35px; // compensating for preloader
  `}
`;

// Match position of the 'resend code' link text
const TimerText = styled.div`
  padding-top: 8px;
  display: inline-block;
`;

class ResendCodeButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      httpRequestInFlight: false,
      countDown: true   // Initial state assumes a code has already been sent 
    };

    // Need a separate recaptcha for each form/button. So we send a recaptcha
    // value even if it's not required in the backend.
    this.recaptchaRef = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?
      React.createRef() :
      {
        current : {
          getValue : () => "",
          reset : () => "",
          execute: this.resendCode
        }
      };
  }

  // Call recaptcha execute if it's there. Else just call resendCode (the dummy entry created above)
  resendCodeWrapper = () => {
    this.recaptchaRef.current.execute();
  }

  resendCode = () => {
    this.setState({
      httpRequestInFlight: true,
      countDown: false
    });

    const recaptchaValue = this.recaptchaRef.current.getValue();
    this.recaptchaRef.current.reset();

    axios({
      method: "POST",
      url: `${this.props.resendEndpoint}${window.location.search}`,
      data: qs.stringify({
        email: this.props.email,
        recaptchaValue
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }).then(({ data }) => {
      this.setState({
        httpRequestInFlight: false,
        countDown: !data.err
      });
      if (data.err) {
        toast(<MessageBox message={data.err.message} messageType="error" />);
      } else {
        toast(<MessageBox message={`A new verification code has been sent to ${this.props.email}`} />);
      }
    });
  };

  countdownCallback = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      this.setState({countDown:false});
      return <span/>;
    }
    return <TimerText>Resend code in <strong>{minutes*60 + seconds} seconds</strong></TimerText>;
  };

  //
  // ReCAPTCHA call sequence is - form's onSubmit, or button's onClick calls a method than calls recaptcha.execute().  This call
  // calls google to get a token, and then calls ReCAPTCHA.onChange().  That method then gets the captcha
  // value to be passed to the backend for validation. 
  render() {
    const { children } = this.props;

    if (this.state.countDown)
      return <Countdown date={Date.now() + 60000} renderer={this.countdownCallback}>children</Countdown>
    return (
      <span>
        { process.env.REACT_APP_RECAPTCHA_SITE_KEY ?
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            size="invisible"
            ref={this.recaptchaRef}
            onChange={this.resendCode}
            badge="inline"
            style={{ display: "none" }}
          /> : "" }
        <ButtonMasqueradingAsLink as="button" onClick={this.resendCodeWrapper} disabled={this.state.httpRequestInFlight || this.state.countDown}>
          {this.state.httpRequestInFlight ? <ThemedSpinner /> : children}
        </ButtonMasqueradingAsLink>
      </span>
    );
  }
}

export default ResendCodeButton;
