import React, { Component, Fragment } from "react";
import MessageBox from "../common-components/message-box";
import { toast } from "../common-components/custom-styled-toast-container";
import axios from "axios";
import qs from "qs";

/**
 * Check if recaptcha is required (server call that allows certain emails thru)
 * @param {*} email email
 * @param {*} recaptchaRef recaptchaRef
 * @returns {void}
 */
function checkReCaptchaAndSubmit(email, recaptchaRef) {
  const { location } = this.props;
  this.setState({
    httpRequestInFlight: true
  })
  axios({
    method: "POST",
    url: `/recaptchaRequired${location.search}`,
    data: qs.stringify({
      email,
    }),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  }).then((captchaFlag) => {
    this.checkReCaptcha = captchaFlag.data.disableRecaptcha;
    if (!captchaFlag.data.disableRecaptcha) {
      recaptchaRef.current.execute();
    } else {
      console.log("Recaptcha disabled");
      this.onSubmit();
    };
  }).catch(err => {
    this.setState({
      httpRequestInFlight: false
    });
    toast(<MessageBox message={err} messageType="error" />);
  });
};

export {checkReCaptchaAndSubmit};