import React, { Component, Fragment } from "react";
import Interpolate from "react-interpolate-component";
import { Link as ReactRouterLink, Redirect } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import CookieUtil from "js-cookie";
import { getContentString } from "../branding";
import LoginForm from "../form-components/login-form";
import CommonHeader from "../common-components/common-header";
import CommonFooter from "../common-components/common-footer";
import { MainContentContainer, SectionFooter } from "../common-components/containers";
import MessageBox from "../common-components/message-box";
import { toast } from "../common-components/custom-styled-toast-container";
import { ThemedLink, ThemedHorizontalRule } from "../common-components/themed-components";
import ConsentManager from "../common-components/cookie-consent/consent-manager";
import Analytics from "../common-components/cookie-consent/analytics";
import { determineSource, determineErrorMessage } from "../common-components/cookie-consent/utils"
import { checkReCaptchaAndSubmit } from "./recaptcha-common";
import XeRecaptchaDisclosure from "../brand-specific-components/xe-recaptcha-disclosure";
import RedirectMappings from "../common-components/redirectMappings";

const InvalidEmailMsg = () => (
  <Interpolate
    with={{
      contactLink: <ThemedLink href={getContentString("login.contactLinkUrl")}>{getContentString("login.contactLinkText")}</ThemedLink>
    }}
    component={Fragment}
  >
    {getContentString("login.invalidEmailMsg")}
  </Interpolate>
);

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      verificationCode: "",
      authState: "login",
      authCode: null,
      userId:0,
      carryForwardData: null,
      httpRequestInFlight: false
    };

    this.checkReCaptchaAndSubmit = checkReCaptchaAndSubmit.bind(this);

    this.recaptchaRef = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?
      React.createRef() :
      {
        current : {
          getValue : () => "",
          reset : () => "",
          execute: this.onSubmit
        }
      };
  }

  componentDidMount() {
    const { location } = this.props;
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
    const redirect_uri = `${queryParams.redirect_uri}`;
    const referenceSource = determineSource(redirect_uri);
    Analytics.trackEvent("Login Started", { referenceSource: referenceSource });
  }

  receiveUpdateFromForm = formState => {
    this.setState({
      email: formState.email,
      password: formState.password
    });
  };

  onSubmit = event => {
    const { email, password } = this.state;
    const { location } = this.props;
    const loginUrlMapping = new Map([      
      ["prd","https://corporate-registration.xe.com"],      
      ["uat","https://staging-corporate-registration.xe.com"],      
      ["sand","https://sandbox-corporate-registration.xe.com"],      
      ["dev","https://integration-corporate-registration.xe.com"]    
    ]);

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const recaptchaValue = this.recaptchaRef.current.getValue();
    this.recaptchaRef.current.reset();

    if (!ConsentManager.isConsentedToCategory("compliance")) {
      ConsentManager.updateConsentState({ compliance: true });
    }
    this.setState({
      httpRequestInFlight: true
    });
    axios({
      method: "POST",
      url: `${location.pathname}${location.search}`,
      data: qs.stringify({
        email,
        password,
        recaptchaValue
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(async ({ data }) => {
        let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        const redirect_uri = `${queryParams.redirect_uri}`;
        let referenceSource = determineSource(redirect_uri);
        if(data.isXeraCorporate){          
          queryParams.redirect_uri = loginUrlMapping.get(process.env.REACT_APP_DEPLOYMENT_TYPE);
        }       

        if (data.err) {
          Analytics.trackEvent("Login Failed", { referenceSource: referenceSource, failReason : determineErrorMessage(data.err), loginMethod : "Email"});
          this.setState({
            httpRequestInFlight: false
          });
          if (data.err.code === "UserNotConfirmedException" || data.err.code === "EmailNotVerifiedException") {
            this.setState({
              authState: "confirmSignup",
              carryForwardData: data.confirmSignupData
            });
          } else if (data.err.code === "PasswordResetRequiredException") {
            this.setState({
              authState: "migrationActionRequired",
            });
          } else {
            toast(<MessageBox message={data.err.message} messageType="error"/>);
          }
        } else {
          if (await ConsentManager.isConsentedToCategory("compliance") || await ConsentManager.isConsentedToCategory("performance")) {
            CookieUtil.set("email", email, { expires: 365 });
          }

          if (data.redirectToHelp) {
            if (process.env.REACT_APP_HELPCENTRE2FA_ENABLED === "yes") {
              window.location = `${data.redirecToHelpUri}?${qs.stringify({
                code: data.authCode,
                state: "",
                helpCentre:data.redirectToHelp
              })}`
            } else {
              window.location = data.redirecToHelpUri;
            }
            return;
          }

          if (data.showMigrationPasswordNotice) {
            this.setState({
              authState: "migrationPasswordNotice",
              authCode: data.authCode
            });
          } else if (RedirectMappings.IsMTRedirect(redirect_uri) && data.showSelectAccountType) {
            // User is logging in to fxweb/galileo but has not registered an account
            // so prompt them what type of account they want.
            this.setState({
              authState: "selectAccountType",
              authCode: data.authCode,
              email: email,
              userId : data.userId,
              referenceSource
            });            
          } else {
            let today = new Date();
            today = today.getFullYear() + "/" + String(today.getMonth() + 1).padStart(2, "0") + "/" + String(today.getDate()).padStart(2, "0");

            Analytics.identifyUser({ email : email, lastLoginDate: today }, {}, data.userId);
            Analytics.trackEvent("Login Completed", { referenceSource: referenceSource }, { useSendBeacon : true });

            window.location = `${queryParams.redirect_uri}?${qs.stringify({
              code: data.authCode,
              state: queryParams.state
            })}`;
          }
        }
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.authState !== nextState.authState ||
      this.state.httpRequestInFlight !== nextState.httpRequestInFlight
    ) {
      return true;
    }
    return false;
  }

  render() {
    if (this.state.authState === "confirmSignup") {
      let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
      queryParams.interceptedFromLogin = true; // this is used for analytics purposes
      return (
        <Redirect
          push
          to={{
            pathname: "/confirm",
            search: qs.stringify(queryParams),
            state: {
              email: this.state.email,
              password: this.state.password,
              confirmSignupData: this.state.carryForwardData
            }
          }}
        />
      );
    } else if (this.state.authState === "migrationPasswordNotice") {
      return (
        <Redirect
          push
          to={{
            pathname: "/migrationPasswordNotice",
            search: this.props.location.search,
            state: {
              authCode: this.state.authCode
            }
          }}
        />
      );
    } else if (this.state.authState === "migrationActionRequired") {
      const showApolloScreens = (process.env.REACT_APP_SHOW_APOLLO === "true" && process.env.REACT_APP_BRAND === "xe");
      return (
        <Redirect
          push
          to={{
            pathname: showApolloScreens ? "/forgotPassword" : "/migrationActionRequired",
            search: showApolloScreens ? `${this.props.location.search}&email=${this.state.email}`: this.props.location.search,
            state: {
              email: this.state.email
            }
          }}
        />
      );
    } else if (this.state.authState === "selectAccountType") {
      return (
        <Redirect
          push
          to={{
            pathname: "/selectAccountType",
            search:  this.props.location.search,
            state: {
              authCode: this.state.authCode,
              authState: this.state.authState,
              email: this.state.email,
              userId: this.state.userId
            }
          }}
        />
      );
    }

    return (
      <Fragment>
        <MainContentContainer>
          <CommonHeader>
            <h1>{getContentString("login.heading")}</h1>
            <p>{getContentString("login.description")}</p>
          </CommonHeader>
          <section>
            <LoginForm
              id="signIn"
              onSubmit={this.onSubmit}
              onCheckRecaptchaAndSubmit={this.checkReCaptchaAndSubmit}
              formStateListener={this.receiveUpdateFromForm}
              isSubmissionProcessing={this.state.httpRequestInFlight}
              emailLabel={getContentString("login.emailLabel")}
              passwordLabel={getContentString("login.passwordLabel")}
              buttonText={getContentString("login.buttonText")}
              invalidEmailMsg={<InvalidEmailMsg />}
              recaptchaRef={this.recaptchaRef}
              disableReCaptcha={this.checkReCaptcha}
            />
            <SectionFooter>
              <p style={{ marginBottom: "15px" }}>
                <ThemedLink
                  as={ReactRouterLink}
                  to={{ pathname: "/forgotPassword", search: this.props.location.search }}
                  data-tracking-id="forgotPassword-link"
                >
                  {getContentString("login.forgotPasswordText")}
                </ThemedLink>
              </p>
              {process.env.REACT_APP_BRAND === "jlp" || process.env.REACT_APP_BRAND === "britline" ? (
                <ThemedHorizontalRule style={{ marginTop: "60px" }} />
              ) : null}
              <p>
                {getContentString("login.noAccountText")}{" "}
                <ThemedLink
                  as={ReactRouterLink}
                  to={{ pathname: "/signup", search: this.props.location.search }}
                  data-tracking-id="signup-link"
                >
                  {getContentString("login.signupText")}
                </ThemedLink>
              </p>
              <XeRecaptchaDisclosure />
            </SectionFooter>
          </section>
        </MainContentContainer>
        <CommonFooter />
      </Fragment>
    );
  }
}

export default Login;
