import styled, { keyframes } from "styled-components";
import { toast, ToastContainer, Slide } from "react-toastify";

/*
 * This is basically a stripped-down version of the CSS that comes in react-toastify/dist/ReactToastify.css
 * In normal usage, you're supposed to import "react-toastify/dist/ReactToastify.css", but we decided to strip
 * out all of the animations/styles we weren't going to use and jury-rig it into a styled-component.
 */

const slideInDown = keyframes`
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }
  to {
    transform: translate3d(0, 0, 0);
  }
`;

const slideOutUp = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
`;

const CustomStyledToastContainer = styled(ToastContainer).attrs(() => ({
  position: toast.POSITION.TOP_CENTER,
  autoClose: false,
  closeButton: false, // removes the default supplied close button. we're using our own custom one
  closeOnClick: false, // this prop controls clicking on the body to close
  draggable: false,
  transition: Slide, // Beware! If you plan to use any other combination of position and transition, you must add the correct animation in CustomStyledToastContainer
  toastClassName: "ToastSizer",
  suppressClassNameWarning: true // ToastContainer actually forwards its className prop to its first child
}))`
  z-index: 9999;
  position: fixed;

  width: 100vw;

  .ToastSizer {
    max-width: 580px;
    margin-left: auto
    margin-right: auto;
    margin-top: 15px;
    padding: 0 15px 0 15px;
  }

  .Toastify__slide-enter--top-center {
    animation: ${slideInDown};
  }

  .Toastify__slide-exit--top-center {
    animation: ${slideOutUp};
  }
`;

const dismissExistingThenToast = (toastContent, toastOptions = {}) => {
  toast.dismiss();
  // Conditional delay added to make the rapid animations less jarring
  if (!toastOptions.delay && !!document.querySelector(".ToastSizer")) {
    toastOptions.delay = 300;
  }
  toast(toastContent, toastOptions);
};

export default CustomStyledToastContainer;
export { dismissExistingThenToast as toast };
