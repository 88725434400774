import React, { Component, Fragment } from "react";
import qs from "qs";
import { getContentString } from "../branding";
import CommonHeader from "../common-components/common-header";
import CommonFooter from "../common-components/common-footer";
import { MainContentContainer } from "../common-components/containers";
import { ThemedButton } from "../common-components/themed-components";

class MigrationPasswordNotice extends Component {
  render() {
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const callbackUri = queryParams.redirect_uri;

    return (
      <Fragment>
        <MainContentContainer>
          <CommonHeader>
            <h1>{getContentString("migrationPasswordNotice.heading")}</h1>
            <p>{getContentString("migrationPasswordNotice.info")}</p>
            <p style={{ marginTop: "20px" }}>{getContentString("migrationPasswordNotice.description")}</p>
          </CommonHeader>
          <ThemedButton
            as="a"
            href={`${callbackUri}?code=${this.props.location.state.authCode}&state=${queryParams.state}`}
          >
            {getContentString("migrationPasswordNotice.buttonText")}
          </ThemedButton>
        </MainContentContainer>
        <CommonFooter />
      </Fragment>
    );
  }
}

export default MigrationPasswordNotice;
