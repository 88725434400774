import React from "react";
import styled from "styled-components";

import { CloseCircleIcon, CheckmarkIcon } from "@xe/atom-image";

const NudeButton = styled.button.attrs({ type: "button" })`
  position: absolute;
  top: 11px;
  right: 11px;

  border: none;
  padding: 0;
  background: transparent;
  vertical-align: middle;
`;

const MessageContainer = styled.div`
  ${({ messageType }) => `
    position: relative;
    border: 1px solid;
    border-color: ${messageType === "error" ? "#b30021" : "#096600"};
    border-radius: 4px;
    background-color: ${messageType === "error" ? "#fff6f8" : "#f3fce8"};
    padding: 12px 50px 12px 12px;
  `}
`;

const MessageBox = props => (
  <MessageContainer messageType={props.messageType}>
    {props.message}
    <NudeButton aria-label="Dismiss notification" onClick={props.closeToast}>
      {props.messageType === "error" ? (
        <CloseCircleIcon />
      ) : (
        <CheckmarkIcon />
      )}
    </NudeButton>
  </MessageContainer>
);

export default MessageBox;
