const determineSource = (redirect_uri) => {
  if (redirect_uri.indexOf("/business") > -1 || redirect_uri.indexOf("corporate-registration") > -1) {
    return "XEMT Business";
  } else if (redirect_uri.indexOf("/personal") > -1 || redirect_uri.indexOf("://ssoxemt") > -1 || redirect_uri.indexOf("xetransfer://") > -1 || redirect_uri.indexOf("transfer") > -1) {
    return "XEMT Personal";
  } else if (redirect_uri.indexOf("xera") > -1) {
    return "XERA";
  } else if (redirect_uri.indexOf("xecd.xe.com") > -1) {
    return "XECD";
  }
  return null;
}

const determineErrorMessage = (err) => {
  if (err.code === "EmailNotVerifiedException") {
    return "Email is not verified.";
  } else if (err.code === "PasswordResetRequiredException") {
    return "Password reset is required.";
  } else if (err.code === "UserNotConfirmedException") {
    return "User is not confirmed.";
  } else if (err.message) {
    return err.message;
  }
  return "Something bad happened.";
}

export { determineSource, determineErrorMessage };