import React, { Component, Fragment } from "react";
import { Link as ReactRouterLink, Redirect } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import CookieUtil from "js-cookie";
import { getContentString } from "../branding";
import SignupForm from "../form-components/signup-form";
import CommonHeader from "../common-components/common-header";
import CommonFooter from "../common-components/common-footer";
import { MainContentContainer, SectionFooter } from "../common-components/containers";
import MessageBox from "../common-components/message-box";
import { toast } from "../common-components/custom-styled-toast-container";
import { ThemedLink, ThemedHorizontalRule } from "../common-components/themed-components";
import ConsentManager from "../common-components/cookie-consent/consent-manager";
import Analytics from "../common-components/cookie-consent/analytics";
import { determineSource } from "../common-components/cookie-consent/utils"
import { checkReCaptchaAndSubmit } from "./recaptcha-common";
import XeRecaptchaDisclosure from "../brand-specific-components/xe-recaptcha-disclosure";

class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      verificationCode: "",
      authState: "signup",
      carryForwardData: null,
      httpRequestInFlight: false
    };

    this.checkReCaptchaAndSubmit = checkReCaptchaAndSubmit.bind(this);

    this.recaptchaRef = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?
      React.createRef() :
      {
        current : {
          getValue : () => "",
          reset : () => "",
          execute: this.onSubmit
        }
      };
  }
  static get loginUrlMapping() {
    return {
      //  map this url                                                to that one.
      // prod
      "https://corporate-registration.xe.com"                      : "https://transfer.xe.com/account/login/home",
      // uat
      "https://staging-corporate-registration.xe.com"              : "https://transfer.xe.com.uat.poc/account/login/home",
      // sandbox
      "https://sandbox-corporate-registration.xe.com"              : "https://transfer.xe.com.test.poc/account/login/home",
      // dev
      "https://integration-corporate-registration.xe.com"          : "https://transfer.xe.com.dev.poc/account/login/home"
    };
  }

    // Only map the loginurl if the redirect-uri is Corporate-registration
    isCorporateRedirectUri = () => {
      const qsParams = new URLSearchParams(window.location.search);
      const redirect = qsParams.get("redirect_uri");
      return process.env.REACT_APP_BRAND === "xe" && redirect.includes("corporate-registration");
    }

  
  mapLoginUrl = () => {
    if (this.isCorporateRedirectUri()) {
      const qsParams = new URLSearchParams(window.location.search);
      const redirect = qsParams.get("redirect_uri");

      qsParams.set("redirect_uri", Signup.loginUrlMapping[redirect]);
      return qsParams.toString();
    }
    return this.props.location.search
  }
  
  receiveUpdateFromForm = formState => {
    this.setState({
      email: formState.email,
      password: formState.password
    });
  };


  onSubmit = event => {
    const { email, password } = this.state;
    const recaptchaValue = this.recaptchaRef.current.getValue();
    this.recaptchaRef.current.reset();

    if (!ConsentManager.isConsentedToCategory("compliance")) {
      ConsentManager.updateConsentState({ compliance: true });
    }

    this.setState({
      httpRequestInFlight: true
    });
    axios({
      method: "POST",
      url: `${this.props.location.pathname}${this.props.location.search}`,
      data: qs.stringify({
        email,
        password,
        recaptchaValue
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(({ data }) => {
        const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
        const redirect_uri = `${queryParams.redirect_uri}`;
        let referenceSource = determineSource(redirect_uri);

        if (data.err) {
          this.setState({
            httpRequestInFlight: false
          });
          if (data.err.code === "DisposableEmailUsed") {
            Analytics.trackEvent("Disposable Email Entered", { referenceSource: referenceSource, email:email, emailEntered:email});
          }
          toast(<MessageBox message={data.err.message} messageType="error" />);
        } else {

          Analytics.identifyUser({ email : email });
          Analytics.trackEvent("Account Created", { referenceSource: referenceSource,email:email });

          this.setState({
            authState: "confirmSignup",
            carryForwardData: data.result
          });
        }
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.authState !== nextState.authState ||
      this.state.httpRequestInFlight !== nextState.httpRequestInFlight
    ) {
      return true;
    }
    return false;
  }

  componentDidMount() {
    if (this.state.authState === "signup") {
      const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
      const redirect_uri = `${queryParams.redirect_uri}`;
      let referenceSource = determineSource(redirect_uri);
      Analytics.trackEvent("Registration Started", { referenceSource: referenceSource, PreviousPageURL: document.referrer });
    }
  }
  render() {
    if (this.state.authState === "confirmSignup") {
      return (
        <Redirect
          push
          to={{
            pathname: "/confirm",
            search: this.props.location.search,
            state: {
              email: this.state.email,
              password: this.state.password,
              confirmSignupData: this.state.carryForwardData
            }
          }}
        />
      );
    }

    return (
      <Fragment>
        <MainContentContainer>
          <CommonHeader>
            <h1>{getContentString("signup.heading")}</h1>
            <p>{getContentString("signup.description")}</p>
          </CommonHeader>
          <section>
            <SignupForm
              id="signup"
              onSubmit={this.onSubmit}
              onCheckRecaptchaAndSubmit={this.checkReCaptchaAndSubmit}
              formStateListener={this.receiveUpdateFromForm}
              isSubmissionProcessing={this.state.httpRequestInFlight}
              emailLabel={getContentString("signup.emailLabel")}
              passwordLabel={getContentString("signup.passwordLabel")}
              buttonText={getContentString("signup.buttonText")}
              recaptchaRef={this.recaptchaRef}
              disableReCaptcha={this.checkReCaptcha}
            />
            <SectionFooter>
              {process.env.REACT_APP_BRAND === "jlp" || process.env.REACT_APP_BRAND === "britline" ? (
                <ThemedHorizontalRule style={{ marginTop: "60px" }} />
              ) : null}
              <p>
                {getContentString("signup.existingAccount")}{" "}
                <ThemedLink
                  as={ReactRouterLink}
                  to={{ pathname: "/login", search: this.mapLoginUrl() }}
                  data-tracking-id="login-link"
                >
                  {getContentString("signup.loginText")}
                </ThemedLink>
              </p>
              <XeRecaptchaDisclosure />
            </SectionFooter>
          </section>
        </MainContentContainer>
        <CommonFooter />
      </Fragment>
    );
  }
}

export default Signup;
