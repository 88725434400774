import { createFocusTrap } from "focus-trap";
import PropTypes from "prop-types";
import React, { useState, useEffect, useLayoutEffect, useRef, Fragment, useMemo } from "react";
import styled, { ThemeProvider, keyframes } from "styled-components";

import Button from "@xe/atom-button";
import { Heading2 } from "@xe/atom-heading";
import { Icon } from "@xe/atom-icon";
import { Label, Toggle } from "@xe/atom-input";
import Link from "@xe/atom-link";
import Paragraph from "@xe/atom-paragraph";
import { MEDIA_QUERY } from "@xe/constants";
import { FluidContainer, LargeContainer } from "@xe/layout-containers";
import { light2 } from "@xe/theme-config-consumer";

import ConsentManager from "./consent-manager";
import { ReactComponent as IconCookie } from "./icon-cookie.svg";
import { ReactComponent as IconChevronRight } from "./icon-chevron-right.svg";

const REGULATORY_URLS = {
  cookiePolicy: "https://help.xe.com/hc/en-gb/articles/4408642862865",
  privacyPolicy:"https://help.xe.com/hc/en-gb/sections/16736362205585"
};

const ConsentHeading = ({ children, onBack }) => (
  <>
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {onBack && (
        <BackButton style={{ position: "absolute", left: 0 }} onClick={onBack}>
          <Icon
            component={IconChevronRight}
            style={{ transform: "scaleX(-1)", height: "16px", width: "16px" }}
          />
        </BackButton>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Heading2
          style={{
            padding: "0",
            margin: "0",
            fontSize: "2rem",
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          {children}
        </Heading2>
        <Icon component={IconCookie} height="30px" width="30px" style={{ marginLeft: "8px" }} />
      </div>
    </div>
    <hr style={{ margin: "16px 0", border: 0, borderTop: "1px solid #ddd" }} />
  </>
);
ConsentHeading.propTypes = {
  children: PropTypes.node,
  onBack: PropTypes.func,
};

const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
`;

const SimplePrompt = ({ onAccept, onCustomize }) => {
  return (
    <>
      <ConsentHeading>Allow optional cookies?</ConsentHeading>
      <ConsentParagraph>
        Cookies help us give you a better experience, improve our products and keep our marketing
        costs down. We won&#39;t turn them on until you accept. <br />{" "}
        <Link href={REGULATORY_URLS.cookiePolicy} situated="inBlockOfText"> Learn more in our cookie policy.</Link> 
      </ConsentParagraph>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button isHollow onClick={onCustomize} style={{ marginRight: "8px" }}>
          Customize
        </Button>
        <Button size="sm" onClick={onAccept}>
          Accept
        </Button>
      </div>
    </>
  );
};
SimplePrompt.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onCustomize: PropTypes.func.isRequired,
};

const categoryStrings = {
  performance: {
    name: "Performance",
    description:
      "These cookies may collect and report personal data to help us understand how our website is performing. These cookies help us to continuously improve the site and offer you a better browsing experience.",
  },
  marketing: {
    name: "Marketing",
    description:
      "These also allow us to see what pages and links users visited so we can provide more relevant ads. We may share this data with other advertising companies for the same purpose.",
  },
  strictlyNecessary: {
    name: "Strictly Necessary",
    description:
    "These cookies are required for our website to work properly. They support website settings, our help centre, account login sessions, and required pieces for products to function.",
  },
};

const AdvancedPrompt = ({ cleanup, onBack }) => {
  const [toggleStates, setToggleStates] = useState({
    performance: ConsentManager.isConsentedToCategory("performance"),
    marketing: ConsentManager.isConsentedToCategory("marketing"),
  });
  const updateToggleStates = updatedValues => {
    setToggleStates(prevState => ({ ...prevState, ...updatedValues }));
  };
  const confirmSelections = () => {
    ConsentManager.updateConsentState(toggleStates);
    cleanup();
  };
  return (
    <>
      <ConsentHeading onBack={onBack}>Customize cookies</ConsentHeading>
      {["performance", "marketing"].map(categoryName => {
        const isConsented = toggleStates[categoryName];
        return (
          <Fragment key={categoryName}>
            <CategoryToggleContainer>
              <CategoryLabel htmlFor={`${categoryName}-consent`}>
                {categoryStrings[categoryName].name}
              </CategoryLabel>
              <Toggle
                id={`${categoryName}-consent`}
                name={`${categoryName}-consent`}
                aria-describedby={`${categoryName}-description`}
                checked={isConsented}
                onChange={() => updateToggleStates({ [categoryName]: !isConsented })}
              />
            </CategoryToggleContainer>
            <ConsentParagraph id={`${categoryName}-description`} isFaded>
              {categoryStrings[categoryName].description}
            </ConsentParagraph>
          </Fragment>
        );
      })}
      <CategoryToggleContainer>
        <CategoryLabel>{categoryStrings["strictlyNecessary"].name}</CategoryLabel>
        <span style={{ margin: 0, color: "#08815c" }}>Always turned on</span>
      </CategoryToggleContainer>
      <ConsentParagraph isFaded>
        {categoryStrings["strictlyNecessary"].description}
      </ConsentParagraph>
      <ConsentParagraph>
        Learn more about the Xe.com{" "}
        <Link situated="inGroupOfLinks" href={REGULATORY_URLS.cookiePolicy}> Cookie Policy</Link> 
        {" "}
        and{" "}
        <Link situated="inGroupOfLinks" href={REGULATORY_URLS.privacyPolicy}> Privacy Policy</Link> 
      </ConsentParagraph>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={confirmSelections}>Confirm</Button>
      </div>
    </>
  );
};
AdvancedPrompt.propTypes = {
  cleanup: PropTypes.func.isRequired,
  onBack: PropTypes.func,
};

const useMorphingHeight = ({ triggerOnChange, containerRef }) => {
  const isInitialRender = useRef(true);
  const containerBoundingRect = useMemo(() => {
    if (!isInitialRender.current) {
      return containerRef.current.getBoundingClientRect();
    }
  }, [triggerOnChange]); // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (!isInitialRender.current) {
      const previous = containerBoundingRect;
      const next = containerRef.current.getBoundingClientRect();
      const deltaHeight = next.height - previous.height;
      if (deltaHeight !== 0) {
        window.requestAnimationFrame(() => {
          containerRef.current.style.transition = "height 0.3s";
          containerRef.current.style.height = `${previous.height}px`;
          containerRef.current.style.opacity = 0;
          window.requestAnimationFrame(() => {
            containerRef.current.style.height = `${next.height}px`;
          });
        });
        containerRef.current.addEventListener(
          "transitionend",
          () => {
            containerRef.current.style.height = "auto";
            containerRef.current.style.transition = "opacity 0.3s";
            containerRef.current.style.opacity = 1;
          },
          { once: true }
        );
      }
    }
  }, [triggerOnChange]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    isInitialRender.current = false;
  }, []);
};

const ConsentBanner = ({ initialScreen = "simplePrompt", cleanup }) => {
  const containerRef = useRef();
  const [screen, setScreen] = useState(initialScreen);

  // Warning: tightly coupled to the entrance animation and markup of this component
  const dismiss = () => {
    const animatedContainer = containerRef.current.parentElement; // FluidContainer doesn't forward refs at this moment, hence the workaround
    const entranceAnimationName = window.getComputedStyle(animatedContainer).animationName;
    window.requestAnimationFrame(() => {
      animatedContainer.style.animationName = "none";
      requestAnimationFrame(() => {
        animatedContainer.style.animationDirection = "reverse";
        animatedContainer.style.animationName = entranceAnimationName;
      });
    });
    animatedContainer.addEventListener("animationend", () => cleanup(), { once: true });
  };

  useEffect(() => {
    const focusTrap = createFocusTrap(containerRef.current, {
      escapeDeactivates: true,
      clickOutsideDeactivates: true,
    });
    focusTrap.activate();
    return () => focusTrap.deactivate();
  }, [screen]); // eslint-disable-line react-hooks/exhaustive-deps

  useMorphingHeight({ triggerOnChange: screen, containerRef });

  return (
    <ThemeProvider theme={light2}>
      <>
        <Banner>
          <LargeContainer
            ref={containerRef}
            style={{
              padding: "24px",
              fontSize: "1.4rem",
              maxHeight: "80vh",
              overflow: "auto",
            }}
          >
            {screen === "simplePrompt" ? (
              <SimplePrompt
                onAccept={() => {
                  ConsentManager.updateConsentState({ performance: true, marketing: true });
                  dismiss();
                }}
                onCustomize={() => setScreen("advancedPrompt")}
              />
            ) : screen === "advancedPrompt" ? (
              <AdvancedPrompt
                onBack={initialScreen === "simplePrompt" ? () => setScreen("simplePrompt") : null}
                cleanup={dismiss}
              />
            ) : null}
          </LargeContainer>
        </Banner>
        <div onClick={() => {
          ConsentManager.updateConsentState({ performance: false, marketing: false });
          dismiss();
        }} style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%", backgroundColor: "#777", opacity: "0.5" }}></div>
      </>
    </ThemeProvider>
  );
};
ConsentBanner.propTypes = {
  initialScreen: PropTypes.oneOf(["simplePrompt", "advancedPrompt"]),
  cleanup: PropTypes.func,
};

export default ConsentBanner;

const slideFromBottom = keyframes`
  from {
    bottom: -100%;
  }
  to {
    bottom: 0;
  }
`;

const slideFromLeft = keyframes`
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
`;

const Banner = styled(FluidContainer)`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;

  width: 100%;
  margin: 0;
  border-radius: 8px 8px 0 0;
  box-shadow: rgba(35, 55, 80, 0.3) 0px 0px 12px;

  animation: ${slideFromBottom} 0.5s;

  ${MEDIA_QUERY.minWidthSm} {
    max-width: 395px;
    margin: 24px;
    border-radius: 8px;
    animation: ${slideFromLeft} 0.5s;
  }
`;

const ConsentParagraph = styled(Paragraph)`
  margin: 0 0 16px;
  max-width: none;
  opacity: ${({ isFaded }) => (isFaded ? 0.8 : 1)};
  font-size: 1.4rem;
`;

const CategoryLabel = styled(Label)`
  font-size: 1.6rem;
  font-weight: bold;
`;

const CategoryToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;

  & > * {
    margin-right: 0;
  }
`;
