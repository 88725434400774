import { brand, defaultBrand } from "./config";
import getContentStringFunction from "./content";
import theme from "./themes";

const getContentString = getContentStringFunction(defaultBrand, brand);

export {
  getContentString,
  theme
};
