import React, { useState } from "react";
import styled from "styled-components";

const Tab2 = styled.button`
  font-size: 16px;
  padding: 0.5rem 1rem;
  cursor: pointer;
  background: white;
  border: 0;
  border-bottom: 1px solid #dee2e6;
  outline: 0;
  flex: 1 1 auto;
  ${({ active }) =>
    active &&
    `
    border-bottom: 1px solid #0d6efd;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
    cursor: default;
  `}
`;

const ButtonGroup = styled.div`
  display: flex;
  text-align:center;
  padding-bottom: 15px;
`;

// A very simple tab component that uses styled buttons rather than react bootstrap's
// tricky to style tabs.
//
// props:
//   tabs = {id:1, title:'my tab name'}
//   onSelect = pass 'title' selected to callback
//   defaultTab = default tab title to select
//   
function TabGroup(props) {
  const [active, setActive] = useState(props.defaultTab);
  return (
      <>
        <ButtonGroup>
          {
            props.tabs.map(({id, title}, ignore) => (
              <Tab2
                type="button"
                key={title}
                active={active === title}
                onClick={() => {
                  // Only call callback if tab changed
                  if (active !== title) {
                    props.onSelect(title);
                    setActive(title);
                  }
                }}
              >
                {title}
              </Tab2>
            ))
          }
        </ButtonGroup>
      </>
  );
}

export {TabGroup};
