import React from "react";
import Interpolate from "react-interpolate-component";
import styled from "styled-components";
import { getContentString } from "../branding";
import { ThemedLink, ThemedHorizontalRule } from "../common-components/themed-components";

const ThemedInverseLink = styled(ThemedLink)`
  ${({ theme }) => `
    &:link, &:visited, &:active {
      color: ${theme.inverseTextColor};
      ${theme.inverseLinkTextDecoration ? `text-decoration: ${theme.inverseLinkTextDecoration};` : ""}
    }
    padding: 0 5px;
  `}
`;

const StyledFooter = styled.footer`
  ${({ theme }) => `
    background: ${theme.inverseBackgroundColor};
    color: ${theme.inverseTextColor};
    width: 100%;
    padding: 10px;
  `}
`;

const FooterConstrictor = styled.div`
  max-width: 991px;
  margin: auto;
`;

const FooterLinksConstrictor = styled.div`
  max-width: ${({ theme }) => theme.footerLinksConstrictorMaxWidth || "580px"};
  margin: auto;
  overflow: hidden;
`;

const LinksContainer = styled.ul`
  ${({ theme }) => `
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    margin-right: -2px; // clever trick combined with the overflow: hidden on container to hide hanging separators when the list wraps

    li {
      flex-grow: 1;
      border-right: 1px solid ${theme.inverseTextColor};
      text-align: center;
      :last-child {
        border-right: none;
      }
    }

    ${theme.extraFooterLinksContainerStyles || ""}
  `}
`;

const LegalTextContainer = styled.div`
  ${({ theme }) => `
    text-align: center;
    margin-top: 10px;

    p {
      margin-top: 10px;
    }

    ${theme.extraFooterLegalTextContainerStyles || ""}
  `}
`;

const HorizontalLineForJLP = styled(ThemedHorizontalRule)`
  margin: 16px 0;
  display: none;
  @media (min-width: 480px) {
    display: block;
  }
`;

const TelephoneLinkForBritline = styled(ThemedInverseLink)`
  &:link,
  &:visited,
  &:active {
    text-decoration: none;
  }
`;

const CommonFooter = () => {
  const currentYear = new Date().getFullYear();
  const legalText = getContentString("commonFooter.legalText");
  const { text: phoneLinkText, destination: phoneLinkDest } = getContentString("commonFooter.telephoneLink");

  return (
    <StyledFooter>
      <FooterConstrictor>
        {process.env.REACT_APP_BRAND === "jlp" ? <HorizontalLineForJLP /> : null}
        <FooterLinksConstrictor>          
          <LinksContainer>                    
            {getContentString(process.env.REACT_APP_ZENDESK_ENABLED === "yes" ? "commonFooter.zendesklinks": "commonFooter.links").map(({ text, destination }) => (
              <li key={text}>
                <ThemedInverseLink href={destination} target="_blank">
                  {text}
                </ThemedInverseLink>
              </li>              
            ))}
          </LinksContainer>
        </FooterLinksConstrictor>
        {process.env.REACT_APP_BRAND === "jlp" ? <HorizontalLineForJLP /> : null}
        {process.env.REACT_APP_BRAND === "britline" ? (
          <p style={{ textAlign: "center", margin: "7px 0" }}>
            Contact us: <TelephoneLinkForBritline href={phoneLinkDest}>{phoneLinkText}</TelephoneLinkForBritline>
          </p>
        ) : null}
        <LegalTextContainer>
          {legalText.map(({ type, text }, index) => {
            switch (type) {
              case "copyright":
                return <Interpolate className="copyright" with={{ currentYear }} component="p" unsafe key={index}>{text}</Interpolate>
              case "disclaimer":
                return <p key={index}>{text}</p>;
              default:
                return null;
            }
          })}
        </LegalTextContainer>
      </FooterConstrictor>
    </StyledFooter>
  );
};

export default CommonFooter;
