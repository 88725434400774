import React, { Component, Fragment } from "react";
import axios from "axios";
import qs from "qs";
import { getContentString } from "../branding";
import ChangePasswordForm from "../form-components/change-password-form";
import CommonHeader from "../common-components/common-header";
import CommonFooter from "../common-components/common-footer";
import { MainContentContainer, SectionFooter } from "../common-components/containers";
import MessageBox from "../common-components/message-box";
import { toast } from "../common-components/custom-styled-toast-container";
import { ThemedLink } from "../common-components/themed-components";

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPassword: "",
      newPassword: "",
      httpRequestInFlight: false
    };
  }

  receiveUpdateFromForm = formState => {
    this.setState({
      currentPassword: formState.currentPassword,
      newPassword: formState.newPassword
    });
  };

  onSubmit = event => {
    event.preventDefault();
    const { currentPassword, newPassword } = this.state;

    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    this.setState({
      httpRequestInFlight: true
    });
    axios({
      method: "POST",
      url: `${this.props.location.pathname}${this.props.location.search}`,
      data: qs.stringify({
        currentPassword,
        newPassword
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(({ data }) => {
        if (data.err) {
          this.setState({
            httpRequestInFlight: false
          });
          toast(<MessageBox message={data.err.message} messageType="error" />);
        } else {
          window.location = `${queryParams.redirect_uri}`;
          return;
        }
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.httpRequestInFlight !== nextState.httpRequestInFlight) {
      return true;
    }
    return false;
  }

  render() {
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    return (
      <Fragment>
        <MainContentContainer>
          <CommonHeader>
            <h1>{getContentString("changePassword.heading")}</h1>
          </CommonHeader>
          <section>
            <ChangePasswordForm
              id="changePassword"
              onSubmit={this.onSubmit}
              formStateListener={this.receiveUpdateFromForm}
              isSubmissionProcessing={this.state.httpRequestInFlight}
              buttonText={getContentString("changePassword.buttonText")}
            />
            <SectionFooter>
              <p>
                <ThemedLink href={queryParams.redirect_uri} data-tracking-id="cancelChangePassword-link">
                  {getContentString("changePassword.cancelText")}
                </ThemedLink>
              </p>
            </SectionFooter>
          </section>
        </MainContentContainer>
        <CommonFooter />
      </Fragment>
    );
  }
}

export default ChangePassword;
