import React, { Component, Fragment } from "react";
import qs from "qs";
import { getContentString } from "../branding";
import SelectAccountTypeForm from "../form-components/select-account-type-form";
import CommonHeader from "../common-components/common-header";
import CommonFooter from "../common-components/common-footer";
import { MainContentContainer, SectionFooter } from "../common-components/containers";
import { ThemedLink } from "../common-components/themed-components";
import { toast } from "../common-components/custom-styled-toast-container";
import MessageBox from "../common-components/message-box";
import { Redirect } from "react-router-dom";
import Analytics from "../common-components/cookie-consent/analytics";

class SelectAccountType extends Component {
  constructor(props) {
    super(props);

    this.authState = "selectAccountType";
    if (!props.location.state || props.location.state.authState !== "selectAccountType") {
      toast(
        <MessageBox
          message="You tried to access the account selection page in an invalid flow. You have been sent back to the login page."
          messageType="error"
        />
      );
    } 
    this.state = props.location.state;
  }

  onSubmit = event => {
    event.preventDefault();

    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    var today = new Date();
    today = today.getFullYear() + "/" + String(today.getMonth() + 1).padStart(2, "0") + "/" + String(today.getDate()).padStart(2, "0");

    Analytics.identifyUser({ email : this.state.email, lastLoginDate: today }, {}, this.state.userId);
    Analytics.trackEvent("Login Completed", { referenceSource: this.state.referenceSource }, { useSendBeacon : true });

    window.location = `${queryParams.redirect_uri}?${qs.stringify({
      code: this.state.authCode,
      state: queryParams.state
    })}`;    
  }

  render() {

    if (!this.state || this.state.authState !== this.authState) {
      return (
        <Redirect
          to={{
            pathname: "login",
            search: this.props.location.search
          }}
        />
      );
    }
  
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const forwardedQueryParams = qs.stringify(queryParams);

    return (
      <Fragment>
        <MainContentContainer>
          <CommonHeader>
            <h1>{getContentString("selectAccount.heading")}</h1>
            <p>{getContentString("selectAccount.description")}</p>
          </CommonHeader>
          <section>
            <SelectAccountTypeForm
              id="selectAccount"
              onSubmit={this.onSubmit}
              formStateListener={this.receiveUpdateFromForm}
              buttonText={getContentString("selectAccount.buttonText")}
            />
            <SectionFooter>
              <p style={{ marginTop: "30px" }}>
                <ThemedLink href={`/logout?${forwardedQueryParams}`} data-tracking-id="logout-link">
                  {getContentString("selectAccount.footer")}
                </ThemedLink>
              </p>
            </SectionFooter>
          </section>
        </MainContentContainer>
        <CommonFooter />
      </Fragment>
    );
  }
}

export default SelectAccountType;
