import React, { Component } from "react";
import { PasswordInput } from "@xe/molecule-form-input";

import { ThemedButton, ThemedSpinner } from "../common-components/themed-components";

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittable: false,
      currentPassword: {
        value: "",
        valid: null
      },
      newPassword: {
        value: "",
        valid: null
      }
    };
  }

  updateField = (fieldName, fieldState) => {
    this.setState(
      {
        [fieldName]: {
          value: fieldState.value,
          valid: fieldState.valid
        }
      },
      () => {
        this.props.formStateListener({
          currentPassword: this.state.currentPassword.value,
          newPassword: this.state.newPassword.value
        });
        this.checkIsFormSubmittable();
      }
    );
  };

  checkIsFormSubmittable = () => {
    if (this.state.currentPassword.valid && this.state.newPassword.valid) {
      this.setState({ isSubmittable: true });
    } else {
      this.setState({ isSubmittable: false });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.isSubmittable !== nextState.isSubmittable ||
      this.props.isSubmissionProcessing !== nextProps.isSubmissionProcessing
    ) {
      return true;
    }
    return false;
  }

  render() {
    const { buttonText } = this.props;

    return (
      <form
        action={this.props.action}
        id={this.props.id}
        method={this.props.method}
        onSubmit={this.props.onSubmit}
      >
        <PasswordInput
          autoComplete="current-password"
          required
          labelText="Current Password"
          inputId="currentPassword"
          inputName="currentPassword"
          onUpdate={fieldState => this.updateField("currentPassword", fieldState)} />
        <PasswordInput
          autoComplete="new-password"
          required
          labelText="New Password"
          inputId="newPassword"
          inputName="newPassword"
          isSignUpMode
          onUpdate={fieldState => this.updateField("newPassword", fieldState)} />
        <ThemedButton
          disabled={!this.state.isSubmittable || this.props.isSubmissionProcessing}
          type="submit"
          data-loading={this.props.isSubmissionProcessing}
        >
          {this.props.isSubmissionProcessing ? <ThemedSpinner /> : buttonText}
        </ThemedButton>
      </form>
    );
  }
};

ChangePasswordForm.defaultProps = {
  action: null,
  buttonText: "",
  id: null,
  method: "post",
  onSubmit: () => {},
  formStateListener: formState => {}
};

export default ChangePasswordForm;
