import React, { Component, Fragment } from "react";
import axios from "axios";
import qs from "qs";
import { Redirect } from "react-router-dom";

import { getContentString } from "../branding";
import ConfirmForgotPasswordForm from "../form-components/confirm-forgot-password-form";
import CommonHeader from "../common-components/common-header";
import CommonFooter from "../common-components/common-footer";
import { MainContentContainer, SectionFooter } from "../common-components/containers";
import MessageBox from "../common-components/message-box";
import { toast } from "../common-components/custom-styled-toast-container";
import ResendCodeButton from "../common-components/resend-code-button";
import ConsentManager from "../common-components/cookie-consent/consent-manager";
import CookieUtil from "js-cookie";

class MigrationActionRequired extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationCode: "",
      password: "",
      httpRequestInFlight: false,
    };

    this.recaptchaRef = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?
      React.createRef() :
      {
        current : {
          getValue : () => "",
          reset : () => "",
          execute: this.onSubmit
        }
      };
  }

  receiveUpdateFromForm = formState => {
    this.setState({
      verificationCode: formState.verificationCode,
      password: formState.password
    });
  };

  onSubmit = event => {

    const recaptchaValue = this.recaptchaRef.current.getValue();
    this.recaptchaRef.current.reset();

    const email = this.props.location.state.email;
    const { verificationCode, password } = this.state;
    const queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    this.setState({
      httpRequestInFlight: true
    });
    axios({
      method: "POST",
      url: `/confirmForgotPassword${this.props.location.search}`,
      data: qs.stringify({
        email,
        password,
        code: verificationCode,
        recaptchaValue
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(async ({ data }) => {
        if (data.err) {
          this.setState({
            httpRequestInFlight: false
          });
          toast(<MessageBox message={data.err.message} messageType="error" />);
        } else {
          if (await ConsentManager.isConsentedToCategory("compliance") || await ConsentManager.isConsentedToCategory("performance")) {
            CookieUtil.set("email", email, { expires: 365 });
          }
          window.location = `${queryParams.redirect_uri}?${qs.stringify({
            code: data.authCode,
            state: queryParams.state
          })}`;
        }
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.httpRequestInFlight !== nextState.httpRequestInFlight) {
      return true;
    }
    return false;
  }

  render() {
    if (!(this.props.location.state && this.props.location.state.email)) {
      toast(
        <MessageBox
          message="You tried to access a migration page in an invalid flow. You have been sent back to the login page."
          messageType="error"
        />
      );
      return (
        <Redirect
          to={{
            pathname: "/login",
            search: this.props.location.search
          }}
        />
      );
    }

    return (
      <Fragment>
        <MainContentContainer>
          <CommonHeader>
            <h1>{getContentString("migrationActionRequired.heading")}</h1>
            <p>{getContentString("migrationActionRequired.info")}</p>
            <p style={{ marginTop: "20px" }}>{getContentString("migrationActionRequired.description")}</p>
          </CommonHeader>
          <section>
            <ConfirmForgotPasswordForm
              id="migrationActionRequired"
              onSubmit={this.onSubmit}
              formStateListener={this.receiveUpdateFromForm}
              isSubmissionProcessing={this.state.httpRequestInFlight}
              buttonText={getContentString("migrationActionRequired.buttonText")}
              inputLabelText={getContentString("migrationActionRequired.inputLabelText")}
              newPasswordPrompt={getContentString("migrationActionRequired.newPasswordPrompt")}
              email={this.props.location.state.email}
              recaptchaRef={this.recaptchaRef}
            />
            <SectionFooter>
              <p>
                {getContentString("migrationActionRequired.resendCodePrompt")}
              </p>
              <p>
                <ResendCodeButton resendEndpoint="/forgotPassword" email={this.props.location.state.email}>
                  {getContentString("migrationActionRequired.resendCodeLinkText")}
                </ResendCodeButton>
              </p>
            </SectionFooter>
          </section>
        </MainContentContainer>
        <CommonFooter />
      </Fragment>
    );
  }
}

export default MigrationActionRequired;
