import React, { Component, Fragment } from "react";
import axios from "axios";
import qs from "qs";
import { Redirect } from "react-router-dom";
import maskEmail from "@xe/util-mask-email";

import { getContentString } from "../branding";
import VerificationCodeForm from "../form-components/verification-code-form";
import CommonHeader from "../common-components/common-header";
import CommonFooter from "../common-components/common-footer";
import { MainContentContainer, SectionFooter } from "../common-components/containers";
import ResendCodeButton from "../common-components/resend-code-button";
import MessageBox from "../common-components/message-box";
import { toast } from "../common-components/custom-styled-toast-container";
import ConsentManager from "../common-components/cookie-consent/consent-manager";
import CookieUtil from "js-cookie";
import processError from "../error-components/error-message-mappings";
import Analytics from "../common-components/cookie-consent/analytics";
import { determineSource } from "../common-components/cookie-consent/utils"

class ConfirmSignup extends Component {
  constructor(props) {
    super(props);

    let authState = "confirmSignup";
    if (!props.location.state) {
      toast(
        <MessageBox
          message="You tried to access the confirmation page in an invalid flow. You have been sent back to the login page."
          messageType="error"
        />
      );
      authState = "login";
    }

    this.state = {
      verificationCode: "",
      authState,
      httpRequestInFlight: false
    };
  }

  receiveUpdateFromForm = formState => {
    this.setState({
      verificationCode: formState.verificationCode
    });
  };

  onSubmit = event => {
    event.preventDefault();
    const { location } = this.props;
    const { email, password } = location.state;

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    this.setState({
      httpRequestInFlight: true
    });
    axios({
      method: "POST",
      url: `/confirm${location.search}`,
      data: qs.stringify({
        email,
        password,
        code: this.state.verificationCode
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(async ({ data }) => {
        if (data.err) {
          this.setState({
            httpRequestInFlight: false
          });
          if (data.err.code === "NotAuthorizedException") {
            this.setState({
              authState: "login"
            });
            /*
             * Special error handling for the rare case where someone is unconfirmed, starts at /login, but uses the
             * wrong password. The confirmation will succeed, but the subsequent automatic login will fail.
             */
            data.err.message = processError(data.err, "/confirm");
          }
          toast(<MessageBox message={data.err.message} messageType="error" />);
        } else {
          if (await ConsentManager.isConsentedToCategory("compliance") || await ConsentManager.isConsentedToCategory("performance")) {
            CookieUtil.set("email", email, { expires: 365 });
          }
          const redirect_uri = `${queryParams.redirect_uri}`;
          let referenceSource = determineSource(redirect_uri);
          Analytics.trackEvent("Email Verified", { referenceSource: referenceSource,email:email }, { useSendBeacon : true });

          window.location = `${queryParams.redirect_uri}?${qs.stringify({
            code: data.authCode,
            state: queryParams.state
          })}`;
        }
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.authState !== nextState.authState ||
      this.state.httpRequestInFlight !== nextState.httpRequestInFlight
    ) {
      return true;
    }
    return false;
  }

  render() {
    if (this.state.authState !== "confirmSignup") {
      return (
        <Redirect
          to={{
            pathname: `/${this.state.authState}`,
            search: this.props.location.search
          }}
        />
      );
    }

    const { email } = this.props.location.state;

    return (
      <Fragment>
        <MainContentContainer>
          <CommonHeader>
            <h1>{getContentString("confirmSignup.heading", { email: email })}</h1>
            <p>{getContentString("confirmSignup.description")}</p>
          </CommonHeader>
          <section>
            <VerificationCodeForm
              id="confirmSignup"
              onSubmit={this.onSubmit}
              formStateListener={this.receiveUpdateFromForm}
              isSubmissionProcessing={this.state.httpRequestInFlight}
              buttonText={getContentString("confirmSignup.buttonText")}
            />
            <SectionFooter>
              <p>
                <ResendCodeButton resendEndpoint="/resendConfirmationCode" email={email}>
                  {getContentString("confirmSignup.resendCodeLinkText")}
                </ResendCodeButton>
              </p>
            </SectionFooter>
          </section>
        </MainContentContainer>
        <CommonFooter />
      </Fragment>
    );
  }
}

export default ConfirmSignup;
