import React from "react";
import styled from "styled-components";
import { XeLogo } from "@xe/atom-image";
import JohnLewisFinanceLogo from "../branding/images/jlp-logo";
import BritlineLogo from "../branding/images/britline-logo";
import LibroLogo from "../branding/images/libro-logo";
import { getContentString } from "../branding";
import { ThemedHorizontalRule } from "../common-components/themed-components";

const StyledHeader = styled.header`
  ${({ theme }) => `
    margin-bottom: 25px;
    text-align: ${theme.headerAlignment || "left"};

    h1 {
      margin-bottom: 4px;
    }
    a img {
      margin-left: 40px;
    }
  `}
`;

const brandLogos = {
  xe: {
    component: XeLogo,
    props: {
      width: "62",
      height: "49",
      isBlueOnWhite: true
    }
  },
  britline: {
    component: BritlineLogo,
    props: {
      height: "70px"
    }
  },
  jlp: {
    component: JohnLewisFinanceLogo
  },
  libro: {
    component: LibroLogo,
    props: {
      width: "62",
      height: "49",
      isBlueOnWhite: true
    }
  }
};

const logoConfig = brandLogos[process.env.REACT_APP_BRAND];
const Logo = logoConfig.component;

const Headline = styled.span`
  ${({ theme }) => `
    display: block;
    font-family: ${theme.headingFontFamily};
    font-size: 2.4rem;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    margin-bottom: 30px;
  `}
`;

const CommonHeader = props => {
  const headline = getContentString("header.headline");
  const homepageUrl = getContentString("homepageLink").destination;
  return (
    <StyledHeader>
      <a href={homepageUrl}>
        <Logo {...logoConfig.props} />
      </a>
      {process.env.REACT_APP_BRAND === "jlp" ? <ThemedHorizontalRule /> : null}
      {headline !== "header.headline" ? <Headline>{headline}</Headline> : null}
      {props.children}
    </StyledHeader>
  );
};

export default CommonHeader;
