import React, { Component, Fragment } from "react";
import styled from "styled-components";
import qs from "qs";
import { Redirect } from "react-router-dom";
import { Get } from "react-axios";
import { ContinueIcon } from "@xe/atom-image";
import maskEmail from "@xe/util-mask-email";

import { getContentString } from "../branding";
import CommonHeader from "../common-components/common-header";
import CommonFooter from "../common-components/common-footer";
import { MainContentContainer, SectionFooter } from "../common-components/containers";
import MessageBox from "../common-components/message-box";
import { toast } from "../common-components/custom-styled-toast-container";
import { ThemedLink } from "../common-components/themed-components";

// Some styles inspired by (plagiarized from) molecule-form-input/generic-input
const ContinueLink = styled.a`
  display: block;
  position: relative;
  background-color: ${({ theme }) => theme.buttonBackgroundColor || "#003cbe"};
  border: solid 1px ${({ theme }) => theme.buttonBackgroundColor || "#003cbe"};
  border-radius: 4px;
  padding: 10px 16px;
  color: #ffffff;
  text-decoration: none;
`;

const DecorationAligner = styled.div`
  position: absolute;
  right: 16px;
  display: inline-block;
`;

class ContinueAs extends Component {
  render() {
    let queryParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });

    // TODO: remove this. Really shouldn't need it because the products are expected to set this param
    if (!queryParams.response_type) {
      queryParams.response_type = "code";
    }

    const forwardedQueryParams = qs.stringify(queryParams);

    return (
      <Fragment>
        <MainContentContainer>
          <CommonHeader>
            <h1>{getContentString("continueAs.heading")}</h1>
          </CommonHeader>
          <section>
            <ContinueLink href={`/oauth2/authorize?${forwardedQueryParams}`}>
              <Get url="/getDataFromSession" params={{ attribute: "email" }}>
                {(error, response) => {
                  if (error) {
                    toast(
                      <MessageBox
                        message="Malformed session detected. Please try logging in again."
                        messageType="error"
                      />
                    );
                    return (
                      <Redirect
                        to={{
                          pathname: "/login",
                          search: forwardedQueryParams
                        }}
                      />
                    );
                  } else if (response) {
                    return maskEmail(response.data.email);
                  }

                  return null;
                }}
              </Get>
              <DecorationAligner>
                <ContinueIcon />
              </DecorationAligner>
            </ContinueLink>
            <SectionFooter>
              <p style={{ marginTop: "30px" }}>
                {getContentString("continueAs.logoutText")}{" "}
                <ThemedLink href={`/logout?${forwardedQueryParams}`} data-tracking-id="logout-link">
                  {getContentString("continueAs.logoutLinkText")}
                </ThemedLink>
              </p>
            </SectionFooter>
          </section>
        </MainContentContainer>
        <CommonFooter />
      </Fragment>
    );
  }
}

export default ContinueAs;
