import React from "react";
import  britLineLogo from "./britLineLogo.svg";



const BritlineLogo = props => (
 <img src ={britLineLogo} style={{width:"260xpx", height:"96px", marginLeft:"-10px"}}  alt ="logo"/>
);

export default BritlineLogo;
