import Cookies from "js-cookie";

class ConsentManager {
  constructor() {
    this.shouldShowConsentPrompt = false;
    this.consentState = {};
    this.onLoadCallbacks = [];
    this.onUpdateCallbacks = [];
    this.knownCookieCategories = ["performance", "marketing", "compliance"];
  }

  /**
   * Initializes consent state based on whether or not the user is in a privacy-protected region.
   *
   * @param {boolean} isDefaultOptOut When false, this function will set the user's consent state to fully consented.
   */
  loadConsentState(isDefaultOptOut) {
    const cookiedConsentState = Cookies.getJSON("xeConsentState") != null ? Cookies.getJSON("xeConsentState") : {};
    const validatedConsentState = {};
    for (const category of this.knownCookieCategories) {
      const defaultValue = category === "compliance" ? false : !isDefaultOptOut;
      const cookiedValue = cookiedConsentState[category];
      if (cookiedValue !== undefined) {
        validatedConsentState[category] = cookiedValue;
      } else {
        validatedConsentState[category] = defaultValue;
      }
    }
    this.consentState = validatedConsentState;
    
    if (Cookies.getJSON("xeConsentState") === undefined && isDefaultOptOut) {
      this.shouldShowConsentPrompt = true;
    } else {
      this.shouldShowConsentPrompt = false;
    }

    for (const callback of this.onLoadCallbacks) {
      callback();
    }
  }

  /**
   * Checks if the user is consented a given category.
   *
   * @param {string} categoryName Name of the category
   * @returns {boolean} True if the user is consented to the given category, false otherwise.
   * @throws {Error} When attempting to check the consent status of an unrecognized category.
   */
  isConsentedToCategory(categoryName) {
    if (this.knownCookieCategories.indexOf(categoryName) < 0) {
      throw new Error(
        `Attempted to check consent status of unrecognized category: ${categoryName}`
      );
    }
    return this.consentState[categoryName];
  }

  /**
   * Update the user's consent state.
   *
   * @param {object} consents An object that will be merged with the current consent state to update it.
   */
  updateConsentState(consents) {
    for (const [key, value] of Object.entries(consents)) {
      if (this.knownCookieCategories.indexOf(key) < 0) {
        throw new Error(`Tried to consent to an unknown category of cookies: ${key}`);
      } else if (typeof value !== "boolean") {
        throw new Error(
          `Non-boolean consent value given for category ${key}: ${value} (type ${typeof value})`
        );
      }
    }

    let domain = ".xe.com";

    //adding the below check until all of our webcomponents point to new .xe.com account-ui
    //The below two lines can be removed part of the MT-11291.
    if (process.env.REACT_APP_ANALYTICS_ENV === "dev" && (window.location.href.indexOf(".xedevelopment.com") > -1)) {
      domain = ".xedevelopment.com";
    }
    if (process.env.REACT_APP_BRAND === "libro" && process.env.REACT_APP_ANALYTICS_ENV == "prd") {
      domain = ".libro.ca";
    }

    this.consentState = { ...this.consentState, ...consents };
    Cookies.set("xeConsentState", this.consentState, { domain: domain, expires: 365 });

    for (const callback of this.onUpdateCallbacks) {
      callback();
    }
  }

  /**
   * Registers a function that will be called when consent state is initialized
   *
   * @param {Function} callback The callback. Note that it receives no arguments. Consent state is up to date when this callback is invoked.
   * @returns {Function} A function that can be called to stop listening for changes.
   */
  onLoad(callback) {
    this.onLoadCallbacks.push(callback);
    // remember that this works because of closures in JS :)
    const removalIndex = this.onLoadCallbacks.length - 1;
    return () => {
      this.onLoadCallbacks.splice(removalIndex, 1);
    };
  }

  /**
   * Registers a function that will be called when the user causes changes to consent state
   *
   * @param {Function} callback The callback. Note that it receives no arguments. Consent state is up to date when this callback is invoked.
   * @returns {Function} A function that can be called to stop listening for consent updates.
   */
  onUpdate(callback) {
    this.onUpdateCallbacks.push(callback);
    // remember that this works because of closures in JS :)
    const removalIndex = this.onUpdateCallbacks.length - 1;
    return () => {
      this.onUpdateCallbacks.splice(removalIndex, 1);
    };
  }
}

const ConsentManagerInstance = new ConsentManager();

export default ConsentManagerInstance;
