import React from "react";
import styled from "styled-components";

const Background = styled.div`
  ${({ theme }) => `
    background-color: #ffffff;
    width: 100%;
    flex-grow: 999;

    @media (min-width: 480px) {
      background-color: ${theme.backdropColor};
      padding-top: 5vh;
    }
  `}
`;

const Middleground = styled.div`
  ${({ theme }) => `
    background-color: ${theme.backgroundColor};
    width: 100%;
    max-width: 580px;
    margin: 0 auto;
  `}
`;

const Foreground = styled.div`
  ${({ theme }) => `
    background-color: ${theme.backgroundColor};
    max-width: 400px;
    padding: 17px 15px 30px 15px;
    margin: auto;
  `}
`;

const MainContentContainer = props => (
  <Background>
    <Middleground>
      <Foreground>{props.children}</Foreground>
    </Middleground>
  </Background>
);

const SectionFooter = styled.footer`
  text-align: center;
`;

export { MainContentContainer, SectionFooter };
