import React, { Component, Fragment } from "react";
import axios from "axios";
import qs from "qs";
import Interpolate from "react-interpolate-component";
import { Redirect } from "react-router-dom";
import { getContentString } from "../branding";
import ConfirmForgotPasswordForm from "../form-components/confirm-forgot-password-form";
import CommonHeader from "../common-components/common-header";
import CommonFooter from "../common-components/common-footer";
import { MainContentContainer, SectionFooter } from "../common-components/containers";
import MessageBox from "../common-components/message-box";
import { toast } from "../common-components/custom-styled-toast-container";
import ResendCodeButton from "../common-components/resend-code-button";
import ConsentManager from "../common-components/cookie-consent/consent-manager";
import CookieUtil from "js-cookie";

class ConfirmForgotPassword extends Component {
  constructor(props) {
    super(props);

    let authState = "confirmForgotPassword";
    if (!props.location.state) {
      toast(
        <MessageBox
          message="You tried to access the confirmation page for forgotten passwords in an invalid flow. You have been sent back to the forgot password page."
          messageType="error"
        />
      );
      authState = "forgotPassword";
    }

    this.recaptchaRef = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?
      React.createRef() :
      {
        current : {
          getValue : () => "",
          reset : () => "",
          execute: this.onSubmit
        }
      };

    this.state = {
      verificationCode: "",
      password: "",
      authState,
      authCode: null,
      httpRequestInFlight: false,
      email: props.location.state.email
    };
  }

  receiveUpdateFromForm = formState => {
    this.setState({
      verificationCode: formState.verificationCode,
      password: formState.password
    });
  };

  onSubmit = event => {
    const recaptchaValue = this.recaptchaRef.current.getValue();
    this.recaptchaRef.current.reset();

    const { verificationCode, password } = this.state;
    const { location } = this.props;
    const { email } = location.state;
    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    this.setState({
      httpRequestInFlight: true
    });
    axios({
      method: "POST",
      url: `${location.pathname}${location.search}`,
      data: qs.stringify({
        email,
        password,
        code: verificationCode,
        recaptchaValue
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
      .then(async ({ data }) => {
        if (data.err) {
          this.setState({
            httpRequestInFlight: false
          });
          toast(<MessageBox message={data.err.message} messageType="error" />);
        } else {
          if (await ConsentManager.isConsentedToCategory("compliance") || await ConsentManager.isConsentedToCategory("performance")) {
            CookieUtil.set("email", email, { expires: 365 });
          }

          if (data.showMigrationPasswordNotice) {
            this.setState({
              authState: "migrationPasswordNotice",
              authCode: data.authCode
            });
          } else {
            window.location = `${queryParams.redirect_uri}?${qs.stringify({
              code: data.authCode,
              state: queryParams.state
            })}`;
          }
        }
      });
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.authState !== nextState.authState ||
      this.state.httpRequestInFlight !== nextState.httpRequestInFlight
    ) {
      return true;
    }
    return false;
  }

  render() {
    if (this.state.authState === "migrationPasswordNotice") {
      return (
        <Redirect
          push
          to={{
            pathname: "/migrationPasswordNotice",
            search: this.props.location.search,
            state: {
              authCode: this.state.authCode
            }
          }}
        />
      );
    } else if (this.state.authState !== "confirmForgotPassword") {
      return (
        <Redirect
          to={{
            pathname: `/${this.state.authState}`,
            search: this.props.location.search
          }}
        />
      );
    }

    const { email } = this.props.location.state;

    return (
      <Fragment>
        <MainContentContainer>
          <CommonHeader>
            <h1>{getContentString("confirmForgotPassword.heading")}</h1>
            <Interpolate
              component="p"
              with={{
                email: <strong>{email}</strong>,
              }}
            >
              {getContentString("confirmForgotPassword.description")}
            </Interpolate>
          </CommonHeader>
          <section>
            <ConfirmForgotPasswordForm
              id="confirmForgotPassword"
              onSubmit={this.onSubmit}
              formStateListener={this.receiveUpdateFromForm}
              isSubmissionProcessing={this.state.httpRequestInFlight}
              buttonText={getContentString("confirmForgotPassword.buttonText")}
              inputLabelText={getContentString("confirmForgotPassword.inputLabelText")}
              newPasswordPrompt={getContentString("confirmForgotPassword.newPasswordPrompt")}
              recaptchaRef={this.recaptchaRef}
              email={email}
            />
            <SectionFooter>
              <p>
                {getContentString("confirmForgotPassword.resendCodePrompt")}
              </p>
              <p>
                <ResendCodeButton resendEndpoint="/forgotPassword" email={email}>
                  {getContentString("confirmForgotPassword.resendCodeLinkText")}
                </ResendCodeButton>
              </p>
            </SectionFooter>
          </section>
        </MainContentContainer>
        <CommonFooter />
      </Fragment>
    );
  }
}

export default ConfirmForgotPassword;
