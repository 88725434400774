import React, {Component} from "react";
import { EmailInput } from "@xe/molecule-form-input";
import ReCAPTCHA from "react-google-recaptcha";
import { ThemedButton, ThemedSpinner } from "../common-components/themed-components";

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmittable: false,
      email: {
        value: "",
        valid: null
      }
    };
  }

  onErrored = (err) => {
    console.log("Error", err);
  }

  updateField = (fieldName, fieldState) => {
    this.setState(
      {
        [fieldName]: {
          value: fieldState.value,
          valid: fieldState.valid
        }
      },
      () => {
        this.props.formStateListener({
          email: this.state.email.value
        });
        this.checkIsFormSubmittable();
      }
    );
  };

  checkIsFormSubmittable = () => {
    if (this.state.email.valid) {
      this.setState({ isSubmittable: true });
    } else {
      this.setState({ isSubmittable: false });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.isSubmittable !== nextState.isSubmittable ||
      this.props.isSubmissionProcessing !== nextProps.isSubmissionProcessing
    ) {
      return true;
    }
    return false;
  }

  render () {
    const { buttonText } = this.props;

    return (
      <form
        action={this.props.action}
        id={this.props.id}
        method={this.props.method}
        onSubmit={e => {
          e.preventDefault();
          this.props.recaptchaRef.current.execute();
        }}
      >
        <EmailInput
          autoComplete="username email"
          labelText="Email"
          inputId="email"
          inputName="email"
          onUpdate={fieldState => this.updateField("email", fieldState)}
          required
        />
        {process.env.REACT_APP_RECAPTCHA_SITE_KEY ?
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            size="invisible"
            ref={this.props.recaptchaRef}
            onChange={this.props.onSubmit}
            badge="inline"
            style={{ display: "none" }}
            onErrored={this.onErrored}
          /> :
          "NoCAPTCHA!"
        }
        <ThemedButton
          disabled={!this.state.isSubmittable || this.props.isSubmissionProcessing}
          type="submit"
          data-loading={this.props.isSubmissionProcessing}
        >
          {this.props.isSubmissionProcessing ? <ThemedSpinner /> : buttonText}
        </ThemedButton>
      </form>
    );
  }
};

ForgotPasswordForm.defaultProps = {
  action: null,
  buttonText: "",
  id: null,
  method: "post",
  onSubmit: () => {},
  formStateListener: formState => {}
};

export default ForgotPasswordForm;
