import React, {Component} from "react";
import {CardGroup} from "../common-components/cards"
import {ThemedButton, ThemedSpinner} from "../common-components/themed-components";
import { withRouter } from "react-router-dom";
import Business from "../branding/images/business.svg";
import Personal from "../branding/images/personal.svg";
import { getContentString } from "../branding";
import RedirectMappings from "../common-components/redirectMappings"


class SelectAccountTypeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab : 0,
      isSubmittable: false,
      accountType: {
        value: ""
      }
    };
  }

  onErrored = (err) => {
    console.log("Error", err);
  }

  // Choose initial tab based on redirect uri
  defaultTab = () => {
    const qsParams = new URLSearchParams(window.location.search);
    const redirect = qsParams.get("redirect_uri");
    const tab = redirect.includes("corporate-registration") ? "Business" : "Personal";

    return tab;
  }

  // Only show tab for XE and only when the redirect_url is one we're expecting.
  // We shouldn't even get to this page for non-Xe though.
  showTabs = () => {
    const qsParams = new URLSearchParams(window.location.search);
    const redirect = qsParams.get("redirect_uri");
    return process.env.REACT_APP_BRAND === "xe" && redirect in RedirectMappings.PrivateCorporateLoginMappings;
  }

  // Toggle the redirect_url (we can toggle as we only have 2 tabs). As we know
  // exactly what redirect_url was initially used the tab mapping is safe.
  toggleTab = (selectedTab) => {
    this.setState({selectedTab : selectedTab});
    const qsParams = new URLSearchParams(window.location.search);
    const redirect = qsParams.get("redirect_uri");
    
    qsParams.set("redirect_uri", RedirectMappings.PrivateCorporateLoginMappings[redirect]);

    // Update history
    this.props.history.push({pathname:"selectAccountType", search : qsParams.toString()});
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  render () {
    const { buttonText } = this.props;
    const cardset = [
      {id:1, title:"Personal",  img:Personal, text:getContentString("selectAccount.personal")}, 
      {id:2, title:"Business", img:Business, text:getContentString("selectAccount.business")}];

    return (
      <form
        action={this.props.action}
        id={this.props.id}
        method={this.props.method}
        onSubmit={this.props.onSubmit}
      >
        <CardGroup tabs={cardset} onSelect={this.toggleTab} defaultTab={this.defaultTab()}></CardGroup>

        <ThemedButton type="submit">{buttonText}</ThemedButton>
      </form>
    );
  }
};

SelectAccountTypeForm.defaultProps = {
  action: null,
  buttonText: "",
  id: null,
  method: "post",
  onSubmit: () => {},
  formStateListener: formState => {}
};

export default withRouter(SelectAccountTypeForm);
