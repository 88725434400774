import React from "react";
import styled, { keyframes } from "styled-components";
import { ButtonLarge } from "@xe/atom-button";
import Link from "@xe/atom-link";

const ThemedButton = styled(ButtonLarge)`
  ${({ theme }) => `
    font-weight: ${theme.buttonFontWeight};
    text-transform: ${theme.buttonTextTransform};
    ${theme.extraButtonStyles ? theme.extraButtonStyles : ""}
  `}
`;

const ThemedLink = styled(Link)`
  ${({ theme }) => `
    &:link, &:visited, &:active {
      color: ${theme.linkColor};
      text-decoration: underline; // FIX ME: atom-link should be underlined by default in the future
    }
  `}
`;

const morphColor = ({ theme }) => keyframes`
  from {
    background-color: ${theme.spinnerColor1};
  }

  to {
    background-color: ${theme.spinnerColor2};
  }
`;

const SpinnerBox = styled.span`
  display: inline-block;
  height: 14px;
  width: 14px;
  border-radius: 5px;
  animation: ${morphColor} 0.9s linear infinite;
  animation-direction: alternate;
`;

const ThemedSpinner = () => (
  <div>
    <SpinnerBox/>
    <SpinnerBox style={{ margin: "0 5px", animationDelay: "0.2s" }}/>
    <SpinnerBox style={{ animationDelay: "0.4s" }}/>
  </div>
);

const ThemedHorizontalRule = styled.hr`
  ${({ theme }) => `
    border-top: 1px solid ${theme.horizontalRuleColor || "black"};
    border-right: none;
    border-bottom: none;
    border-left: none;
    margin: 10px 0;
  `}
`;

export {
  ThemedButton,
  ThemedLink,
  ThemedSpinner,
  ThemedHorizontalRule
};
